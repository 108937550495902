import {Controller} from "@hotwired/stimulus"
import {driver} from 'driver.js';

// Connects to data-controller="talents--tour--index"
export default class extends Controller {
  static targets = ['btnAdd']

  connect() {
    if(window.innerWidth > 769){
      let isRestartTalent = JSON.parse(localStorage.getItem('is_retake_talent_tour') === 'true')

      if (!companyTalentCountPositive && isRestartTalent) {
        this.secondStepJob()
      } else if (isRestartTalent) {
        this.secondStepJob()
      }
    }
  }

  secondStepJob() {
    const driverObjSecondStepTalent = driver({
      showProgress: true,
      progressText: '2/3',
      stagePadding: 6,
      stageRadius: 50,
      allowClose: false,
      overlayOpacity: 0.6,
      onPopoverRender: (popover) => {
        popover.closeButton.style.display = 'none'

        let skipBtn = document.createElement('button')
        skipBtn.innerText = talentsTourTranslations.btn
        skipBtn.classList.add('custom-skip-btn')
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener('click', () => {
          localStorage.setItem('is_retake_job_tour', false)
          localStorage.setItem('is_retake_talent_tour', false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjSecondStepTalent.destroy()
        })
      },
      steps: [{
        element: this.btnAddTarget,
        popover: {
          title: talentsTourTranslations.steps.second.title,
          side: 'left',
          align: 'end',
          showButtons: ['close'],
          popoverClass: 'second-step-talent'
        }
      }],
      onCloseClick: () => {
        localStorage.setItem('is_retake_job_tour', false)
        localStorage.setItem('is_retake_talent_tour', false)
        this.checkList()
        driverObjSecondStepTalent.destroy();
      },
    });

    driverObjSecondStepTalent.drive();
  }

  checkList() {
    const targetController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='layouts--check-list']"),
      'layouts--check-list'
    )

    targetController.showCheckList()
  }
}