currentCompanyMemberIsAdmin = checkWindowObject('currentCompanyMemberIsAdmin')
currentCompanyMemberDisplaySalary = checkWindowObject('currentCompanyMemberDisplaySalary')
importTalentGenderOptions = checkWindowObject('importTalentGenderOptions')
importTalentBirthdayDefaultDate = checkWindowObject('importTalentBirthdayDefaultDate')
ImportTalentGraduatedYearOptions = checkWindowObject('ImportTalentGraduatedYearOptions')
importTalentEnglishLevelOptions = checkWindowObject('importTalentEnglishLevelOptions')
importTalentPlaceholderTranslations = checkWindowObject('importTalentPlaceholderTranslations')

function checkWindowObject(obj) { return typeof window[obj] !== 'undefined' && window[obj] }

let importTalentColumns = [
  {
    data: 'import_status',
    renderer: 'importStatusRenderer',
    readOnly: true
  },
  {
    data: 'full_name',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['full_name'],
    validator: 'fullNameValidator',
    width: 255
  },
  {
    data: 'email',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['email'],
    validator: 'emailValidator',
    width: 255
  },
  {
    data: 'active_job_id',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['active_job_id'],
    width: 255
  },
  {
    data: 'cv',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['cv'],
    readOnly: true,
    width: 255
  },
  {
    data: 'company_member_id',
    placeholder: importTalentPlaceholderTranslations['company_member_id'],
    renderer: 'companyMemberRenderer',
    width: 255
  },
  {
    data: 'phone_number',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['phone_number'],
    validator: 'phoneNumberLengthValidator',
    width: 150
  },
  {
    data: 'current_job_title',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['current_job_title'],
    validator: 'stringLengthValidator',
    width: 255
  },
  {
    data: 'current_company_name',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['current_company_name'],
    validator: 'stringLengthValidator',
    width: 255
  },
  {
    data: 'gender',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['gender'],
    readOnly: true,
    width: 120
  },
  {
    data: 'birthday',
    type: 'date',
    correctFormat: true,
    dateFormat: 'YYYY-MM-DD',
    defaultDate: importTalentBirthdayDefaultDate,
    datePickerConfig: {
      firstDay: 0
    },
    placeholder: importTalentPlaceholderTranslations['birthday'],
    width: 150
  },
  {
    data: 'nationality_id',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['nationality_id'],
    width: 150
  },
  {
    data: 'nickname',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['nickname'],
    validator: 'stringLengthValidator',
    width: 255
  },
  {
    data: 'linkedin_url',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['linkedin_url'],
    validator: 'urlValidator',
    width: 255
  },
  {
    data: 'current_salary',
    placeholder: importTalentPlaceholderTranslations['current_salary'],
    renderer: 'salaryRenderer',
    width: 150
  },
  {
    data: 'expected_salary',
    placeholder: importTalentPlaceholderTranslations['expected_salary'],
    renderer: 'salaryRenderer',
    width: 150
  },
  {
    data: 'work_experience',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['work_experience'],
    validator: 'textLengthValidator',
    width: 255
  },
  {
    data: 'strong_point',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['strong_point'],
    validator: 'textLengthValidator',
    width: 255
  },
  {
    data: 'degree_id',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['degree_id'],
    width: 150
  },
  {
    data: 'major',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['major'],
    validator: 'stringLengthValidator',
    width: 255
  },
  {
    data: 'graduated_year',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['graduated_year'],
    width: 150
  },
  {
    data: 'institution_name',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['institution_name'],
    validator: 'stringLengthValidator',
    width: 255
  },
  {
    data: 'gpa_score',
    type: 'numeric',
    numericFormat: {
      pattern: '0.0'
    },
    placeholder: importTalentPlaceholderTranslations['gpa_score'],
    width: 150
  },
  {
    data: 'living_locations',
    type: 'text',
    placeholder: importTalentPlaceholderTranslations['living_locations'],
    validator: 'stringLengthValidator',
    width: 255
  },
  {
    data: 'english_level_id',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['english_level_id'],
    readOnly: true,
    width: 150
  },
  {
    data: 'language_ids',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['language_ids'],
    readOnly: true,
    width: 255
  },
  {
    data: 'start_working_date',
    type: 'date',
    correctFormat: true,
    dateFormat: 'YYYY-MM-DD',
    datePickerConfig: {
      firstDay: 0
    },
    placeholder: importTalentPlaceholderTranslations['start_working_date'],
    width: 255
  },
  {
    data: 'additional_file1',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['additional_file1'],
    readOnly: true,
    width: 255
  },
  {
    data: 'additional_file2',
    type: 'dropdown',
    placeholder: importTalentPlaceholderTranslations['additional_file2'],
    readOnly: true,
    width: 255
  },
  {
    data: 'cv_type',
    readOnly: true
  },
  {
    data: 'cv_content',
    readOnly: true
  },
  {
    data: 'additional_file1_type',
    readOnly: true
  },
  {
    data: 'additional_file1_content',
    readOnly: true
  },
  {
    data: 'additional_file2_type',
    readOnly: true
  },
  {
    data: 'additional_file2_content',
    readOnly: true
  }
]

module.exports = { importTalentColumns }
