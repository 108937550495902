import { Controller } from "@hotwired/stimulus"
import ScrollBooster from 'scrollbooster';

// Connects to data-controller="selection-processes--sections"
export default class extends Controller {
  static targets = ['tabsViewport', 'tabsList']

  initialize() {
    this.loaded()
  }

  loaded() {
    this.scrollBooster()
  }

  scrollBooster() {
    new ScrollBooster({
      viewport: this.tabsViewportTarget,
      content: this.tabsListTarget,
      direction: 'horizontal',
      scrollMode: 'transform'
    });
  }
}
