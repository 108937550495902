import { Controller } from "@hotwired/stimulus"

let close = {
  autoDuration: 6000,
  duration: 600
}

// Connects to data-controller="layouts--header-flash"
export default class extends Controller {
  static targets = ['flashContainer', 'headerContainer']

  initialize() {
    this.loaded()
  }

  loaded() {
    this.auto(close.autoDuration)
  }

  auto(duration) {
    const that = this

    if (!that.hasFlashContainerTarget) return

    setTimeout(function() {
      that.flashContainerTarget.classList.contains('disable-auto-remove') || that.flashContainerTarget.classList.add('hidden')
      that.remove(close.duration)
    }, duration)
  }

  close() {
    this.flashContainerTarget.classList.add('hidden')
    this.remove(close.duration)
  }

  remove(duration) {
    const that = this

    that.element.classList.add('changing')

    setTimeout(function() {
      that.element.classList.remove('changing')
      that.flashContainerTarget.classList.contains('disable-auto-remove') || that.flashContainerTarget.remove()
    }, duration)
  }
}
