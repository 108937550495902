import { Controller } from "@hotwired/stimulus"
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';

// Connects to data-controller="selection-processes--filters"
export default class extends Controller {
  static targets = ['form', 'formMobile', 'container', 'content']

  toggle() {
    this.containerTarget.classList.toggle('active')
    this.contentTarget.classList.toggle('active')

    this.containerTarget.classList.contains('active') ?
      disableBodyScroll(this.containerTarget) : enableBodyScroll(this.containerTarget)
  }

  submit(event) {
    event.preventDefault()

    Array.from(this.formTarget.elements).forEach((element) => {
      element.value || element.removeAttribute('name')
    })

    this.formTarget.submit()
  }

  submitMobile(event) {
    event.preventDefault()

    Array.from(this.formMobileTarget.elements).forEach((element) => {
      element.value || element.removeAttribute('name')
    })

    this.formMobileTarget.submit()
  }
}
