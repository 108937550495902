import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="companies--members--switch"
export default class extends Controller {
  static targets = ['checkbox']

  connect() {
    this.toggle()
  }

  toggle() {
    this.element.classList.toggle('active', this.checkboxTarget.checked)
  }
}
