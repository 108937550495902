import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="companies--members--select"
export default class extends Controller {
  static classes = ['state']
  static targets = ['select', 'selectMultiple', 'checkboxContainer', 'checkbox']

  connect() {
    this.selectOptions()
    this.selectMultipleOptions()
    this.toggleCheckbox()
  }

  loadHiddenSelect(inputHidden, values) {
    inputHidden.setAttribute('value', values.join('-'))
  }

  selectOptions() {
    this.selectTargets.forEach((element) => {
      new SlimSelect({
        select: element,
        settings: {
          showSearch: false,
          openPosition: 'down'
        }
      })
    })
  }

  selectMultipleOptions() {
    if (!this.hasSelectMultipleTarget) return

    this.selectMultipleTargets.forEach((element) => {
      const that = this,
        inputHidden = element.parentNode.querySelector('input[type=hidden]'),
        inputCheckedOptions = element.querySelectorAll('option:checked'),
        inputName = element.dataset.name || element.name

      new SlimSelect({
        select: element,
        settings: {
          placeholderText: companyMemberPlaceholderTranslations[`${inputName}`],
          searchPlaceholder: companyMemberPlaceholderTranslations.search,
          searchText: companyMemberPlaceholderTranslations.no_data_found,
          searchHighlight: true,
          openPosition: 'down'
        },
        events: {
          afterChange: (info) => {
            that.loadHiddenSelect(inputHidden, that.convertToArray(info))
          }
        }
      })

      this.loadHiddenSelect(inputHidden, this.convertToArray(inputCheckedOptions))
      element.removeAttribute('name')
    })
  }

  convertToArray(obj) {
    return Array.from(obj).map(obj => obj.value).sort((a, b) => {
      return a - b
    })
  }

  change() {
    this.toggleCheckbox()
  }

  toggleCheckbox() {
    if (!this.hasCheckboxContainerTarget) return

    const isAdmin = this.selectTarget.value == 'admin'

    isAdmin && (this.checkboxTarget.checked = true)
    isAdmin && this.checkboxContainerTarget.classList.add('active')
    this.checkboxContainerTarget.classList.toggle('disabled', isAdmin)
  }
}

