import {Controller} from "@hotwired/stimulus"
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'

// Connects to data-controller="talents--filters"
export default class extends Controller {
  static targets = ['form', 'formMobile', 'container', 'content', 'checkbox', 'checkboxMobile', 'displays', 'displaysMobile']

  connect() {
    this.events()
  }

  toggle() {
    this.containerTarget.classList.toggle('active')
    this.contentTarget.classList.toggle('active')

    this.containerTarget.classList.contains('active') ?
      disableBodyScroll(this.containerTarget) : enableBodyScroll(this.containerTarget)
  }

  filterCheckbox(data, target) {
    const that = this
    let displays = target.value.split('-')

    data.forEach((element) => {
      element.addEventListener('click', function(event) {

        if (element.checked) {
          if (element.className === 'all') {
            that.selectedAll(data)
            displays.push(element.className)

            displays = displays.filter(value => value === 'all')
            target.value = displays.join()
          } else {
            that.unselectedAll(data)
            displays.push(element.className)

            displays = displays.filter(value => value !== 'all')
            target.value = displays.join('-')
          }
        } else {
          if (element.className === 'all') that.unselectedAll(data)

          displays.forEach((name, index) => {
            if (name === element.className) {
              displays.splice(index, 1)
            }
          })

          displays = displays.filter(value => value !== 'all')
          target.value = displays.length ? displays.join('-') : 'not_applied-closed'
        }
      })
    })
  }

  selectedAll(data) {
    data.forEach((element) => {
      element.checked = element.className === 'all'
    })
  }

  unselectedAll(data) {
    data.forEach((element) => {
      if (element.className === 'all') {
        element.checked = false
      }
    })
  }

  submit(event) {
    event.preventDefault()

    Array.from(this.formTarget.elements).forEach((element) => {
      element.value || element.removeAttribute('name')
    })

    this.formTarget.submit()
  }

  submitMobile(event) {
    event.preventDefault()

    Array.from(this.formMobileTarget.elements).forEach((element) => {
      element.value || element.removeAttribute('name')
    })

    this.formMobileTarget.submit()
  }

  events() {
    if (!this.hasDisplaysTarget) return

    this.filterCheckbox(this.checkboxTargets, this.displaysTarget)
    this.filterCheckbox(this.checkboxMobileTargets, this.displaysMobileTarget)
  }
}
