import { Controller } from "@hotwired/stimulus"
import {driver} from 'driver.js';

// Connects to data-controller="jobs--tour--new"
export default class extends Controller {
  static targets = ['fieldHighlight', 'submitHighlight', 'select', 'input']

  connect() {
    if (window.innerWidth > 769) {
      let isRestartJob = JSON.parse(localStorage.getItem('is_retake_job_tour') === 'true')

      if (!companyJobCountPositive && isRestartJob) {
        this.thirdStepJob()
      } else if (isRestartJob) {
        this.thirdStepJob()
      }
    }
  }

  thirdStepJob() {
    const driverObjThirdStepJob = driver({
      showProgress: true,
      progressText: '3/3',
      stagePadding: 15,
      stageRadius: 20,
      allowClose: false,
      overlayOpacity: 0.6,
      onPopoverRender: (popover) => {
        popover.closeButton.style.display = 'none'

        let skipBtn = document.createElement('button')
        skipBtn.innerText = jobsTourTranslations.btn
        skipBtn.classList.add('custom-skip-btn')
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener('click', () => {
          localStorage.setItem('is_retake_job_tour', false)
          localStorage.setItem('is_retake_talent_tour', false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjThirdStepJob.destroy()
        })

        popover.description.removeChild(popover.description.childNodes[0])

        let nextBtn = document.createElement('button')
        nextBtn.innerText = jobsTourTranslations.steps.third.next
        nextBtn.classList.add('next-btn')
        popover.description.append(nextBtn)

        nextBtn.addEventListener('click', () => {
          this.thirdStepJobSubmit()
        })

        if (this.selectTarget.querySelector('select').value && this.inputTarget.value) {
          popover.description.querySelector('.next-btn').classList.add('active')
        }

        this.inputTarget.addEventListener('input', (event) => {
          const value = event.target.value

          if (value.trim() && this.selectTarget.querySelector('select').value.trim()) {
            popover.description.querySelector('.next-btn').classList.add('active')
          } else {
            popover.description.querySelector('.next-btn').classList.remove('active')
          }
        })

        this.selectTarget.querySelector('select').addEventListener('change', (event) => {
          const value = event.target.value

          if (value.trim() && this.inputTarget.value.trim()) {
            popover.description.querySelector('.next-btn').classList.add('active')
          } else {
            popover.description.querySelector('.next-btn').classList.remove('active')
          }
        })
      },
      steps: [{
        element: this.fieldHighlightTarget, popover: {
          title: jobsTourTranslations.steps.third.title,
          description: jobsTourTranslations.steps.third.description,
          side: 'top',
          align: 'end',
          showButtons: ['close'],
          popoverClass: 'third-step-job',
        }
      }],
      onCloseClick: () => {
        localStorage.setItem('is_retake_job_tour', false)
        localStorage.setItem('is_retake_talent_tour', false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjThirdStepJob.destroy();
      }
    })

    driverObjThirdStepJob.drive();
  }

  thirdStepJobSubmit() {
    const driverObjThirdStepJobSubmit = driver({
      showProgress: true,
      progressText: '3/3',
      stagePadding: 6,
      stageRadius: 50,
      allowClose: false,
      overlayOpacity: 0.6,
      onPopoverRender: (popover) => {
        popover.closeButton.style.display = 'none'

        let skipBtn = document.createElement('button')
        skipBtn.innerText = jobsTourTranslations.btn
        skipBtn.classList.add('custom-skip-btn')
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener('click', () => {
          localStorage.setItem('is_retake_job_tour', false)
          localStorage.setItem('is_retake_talent_tour', false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjThirdStepJobSubmit.destroy()
        })

        popover.description.removeChild(popover.description.childNodes[0])
      },
      steps: [{
        element: this.submitHighlightTarget, popover: {
          title: jobsTourTranslations.steps.third.submit,
          description: '',
          side: 'right',
          align: 'end',
          showButtons: ['close'],
          popoverClass: 'third-step-submit-job',
        }
      }],
      onCloseClick: () => {
        localStorage.setItem('is_retake_job_tour', false)
        localStorage.setItem('is_retake_talent_tour', false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjThirdStepJobSubmit.destroy();
      }
    })

    driverObjThirdStepJobSubmit.drive();
  }

  checkList() {
    const targetController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='layouts--check-list']"),
      'layouts--check-list'
    )

    targetController.showCheckList()
  }
}