import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="talents--tasks--status"
export default class extends Controller {
  static targets = ['submit']

  change() {
    this.submitTarget.click()
  }
}
