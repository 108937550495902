import { Controller } from "@hotwired/stimulus"
import Handsontable from 'handsontable'
import { importTalentColumns } from './import_talents/columns'
import { importTalentCells } from './import_talents/cells'
import { importStatusRenderer } from './base/import_status_renderer'
import { companyMemberRenderer } from './base/company_member_renderer'
import { salaryRenderer } from './base/salary_renderer'
import { activeJobSelection, companyMemberSelection, genderSelection, nationalitySelection, degreeSelection,
  graduatedYearSelection, languageSelection, englishLevelSelection } from './import_talents/selections'

import { cv, additionalFile1, additionalFile2 } from './import_talents/attachments'
import { fullNameValidator, emailValidator, phoneNumberLengthValidator, salaryValidator,
  stringLengthValidator, textLengthValidator, urlValidator } from '../transfer/base/validators'
import {Modal} from 'bootstrap';

function replaceBreakTags(text) {
  return text.replace(/<br\s*\/?>/gi, '\n')
}

function decodeHTMLEntities(text) {
  const parser = new DOMParser(), doc = parser.parseFromString(text, 'text/html')

  return replaceBreakTags(doc.documentElement.textContent)
}

function buildFormSelection(formTarget, fieldName) {
  const formData = new FormData(formTarget)
  const serializedData = Object.fromEntries(formData.entries())

  return serializedData[fieldName]
}

function buildFormAttachment(formTarget, fieldName) {
  const formData = new FormData(formTarget)

  return formData.get(fieldName)
}

function validateTable(talentTable) {
  return new Promise((resolve) => {
    talentTable.validateCells(function(valid) {
      resolve(valid)
    })
  })
}

let talentTable = {}, minSpareRows = 1, initializeRows = 5,
  targetHeaderCols = [1, 2], targetHiddenColumns = [29, 30, 31, 32, 33, 34]

// Connects to data-controller="transfer--import-talents"
export default class extends Controller {
  static targets = ['table', 'activeJobSelection', 'companyMemberSelection', 'genderSelection',
    'nationalitySelection', 'degreeSelection', 'graduatedYearSelection', 'languageSelection', 'englishLevelSelection',
    'cv', 'additionalFile1', 'additionalFile2', 'activeJobSelectionForm', 'companyMemberSelectionForm', 'genderSelectionForm',
    'nationalitySelectionForm', 'degreeSelectionForm', 'graduatedYearSelectionForm', 'languageSelectionForm',
    'englishLevelSelectionForm', 'cvForm', 'additionalFile1Form', 'additionalFile2Form','data', 'modal',
    'formImport']

  connect() {
    this.regisRenderers()
    this.registerValidators()
    this.loadTalentData()
    this.loadTable()
    this.loadSelections()
    this.loadAttachments()
  }

  regisRenderers() {
    Handsontable.renderers.registerRenderer('importStatusRenderer', importStatusRenderer)
    Handsontable.renderers.registerRenderer('companyMemberRenderer', companyMemberRenderer)
    Handsontable.renderers.registerRenderer('salaryRenderer', salaryRenderer)
  }

  registerValidators() {
    Handsontable.validators.registerValidator('fullNameValidator', fullNameValidator)
    Handsontable.validators.registerValidator('emailValidator', emailValidator)
    Handsontable.validators.registerValidator('phoneNumberLengthValidator', phoneNumberLengthValidator)
    Handsontable.validators.registerValidator('salaryValidator', salaryValidator)
    Handsontable.validators.registerValidator('stringLengthValidator', stringLengthValidator)
    Handsontable.validators.registerValidator('textLengthValidator', textLengthValidator)
    Handsontable.validators.registerValidator('urlValidator', urlValidator)
  }

  loadTalentData() {
    if(importTalentData.length > 0 ) {
      importTalentData.map((talent) => {
        return Object.keys(talent).forEach((attr) => {
          if (typeof talent[attr] === 'string') { (talent[attr] = decodeHTMLEntities(talent[attr])) }
        })
      })
    } else {
      importTalentData = Array(initializeRows).fill().map(() => Object.assign({}, importTalentDataSchema))
    }
  }

  loadTable() {
    talentTable = new Handsontable(this.tableTarget, {
      data: importTalentData,
      dataSchema: importTalentDataSchema,
      colHeaders: importTalentColHeaders,
      rowHeaders: true,
      columns: importTalentColumns,
      cells: importTalentCells,
      height: 'auto',
      width: 'auto',
      autoWrapRow: true,
      autoWrapCol: true,
      contextMenu: ['row_below', 'remove_row', 'clear_column'],
      minSpareRows: minSpareRows,
      maxRows: importTalentLimit,
      comments: true,
      hiddenColumns: {
        columns: targetHiddenColumns,
        indicators: false
      },
      afterGetColHeader: function(col, th) {
        if (targetHeaderCols.includes(col)) { th.classList.add('required') }
      },
      licenseKey: 'non-commercial-and-evaluation'
    })

    talentTable.getData().length > minSpareRows && talentTable.validateCells()
  }

  loadSelections() {
    const otherSelectionContainer = this.element.querySelectorAll('.import-selection')

    activeJobSelection(talentTable, this.activeJobSelectionTarget, otherSelectionContainer)
    companyMemberSelection(talentTable, this.companyMemberSelectionTarget, otherSelectionContainer)
    genderSelection(talentTable, this.genderSelectionTarget, otherSelectionContainer)
    nationalitySelection(talentTable, this.nationalitySelectionTarget, otherSelectionContainer)
    degreeSelection(talentTable, this.degreeSelectionTarget, otherSelectionContainer)
    graduatedYearSelection(talentTable, this.graduatedYearSelectionTarget, otherSelectionContainer)
    languageSelection(talentTable, this.languageSelectionTarget, otherSelectionContainer)
    englishLevelSelection(talentTable, this.englishLevelSelectionTarget, otherSelectionContainer)
  }

  loadAttachments() {
    const otherSelectionContainer = this.element.querySelectorAll('.import-selection')

    cv(talentTable, this.cvTarget, otherSelectionContainer)
    additionalFile1(talentTable, this.additionalFile1Target, otherSelectionContainer)
    additionalFile2(talentTable, this.additionalFile2Target, otherSelectionContainer)
  }

  toggleActiveJobSelection() {
    this.activeJobSelectionTarget.classList.toggle('active')
  }

  toggleCompanyMemberSelection() {
    this.companyMemberSelectionTarget.classList.toggle('active')
  }

  toggleGenderSelection() {
    this.genderSelectionTarget.classList.toggle('active')
  }

  toggleNationalitySelection() {
    this.nationalitySelectionTarget.classList.toggle('active')
  }

  toggleDegreeSelection() {
    this.degreeSelectionTarget.classList.toggle('active')
  }

  toggleGraduatedYearSelection() {
    this.graduatedYearSelectionTarget.classList.toggle('active')
  }

  toggleLanguageSelection() {
    this.languageSelectionTarget.classList.toggle('active')
  }

  toggleEnglishLevelSelection() {
    this.englishLevelSelectionTarget.classList.toggle('active')
  }

  toggleCv() {
    this.cvTarget.classList.toggle('active')
  }

  toggleAdditionalFile1() {
    this.additionalFile1Target.classList.toggle('active')
  }

  toggleAdditionalFile2() {
    this.additionalFile2Target.classList.toggle('active')
  }

  submitActiveJobSelection(event) {
    event.preventDefault()

    const activeJobId = buildFormSelection(this.activeJobSelectionFormTarget, 'talent[active_job_id]')
    const talentRow = buildFormSelection(this.activeJobSelectionFormTarget, 'talent[row]')

    talentTable.setDataAtRowProp(talentRow, 'active_job_id', activeJobId)
    this.activeJobSelectionTarget.classList.remove('active')
  }

  submitCompanyMemberSelection(event) {
    event.preventDefault()

    const companyMemberId = buildFormSelection(this.companyMemberSelectionFormTarget, 'talent[company_member_id]')
    const talentRow = buildFormSelection(this.companyMemberSelectionFormTarget, 'talent[row]')

    currentCompanyMemberIsAdmin && talentTable.setDataAtRowProp(talentRow, 'company_member_id', companyMemberId)
    this.companyMemberSelectionTarget.classList.remove('active')
  }

  submitGenderSelection(event) {
    event.preventDefault()

    const gender = buildFormSelection(this.genderSelectionFormTarget, 'talent[gender]')
    const talentRow = buildFormSelection(this.genderSelectionFormTarget, 'talent[row]')

    talentTable.setDataAtRowProp(talentRow, 'gender', gender)
    this.genderSelectionTarget.classList.remove('active')
  }

  submitNationalitySelection(event) {
    event.preventDefault()

    const nationalityId = buildFormSelection(this.nationalitySelectionFormTarget, 'talent[nationality_id]')
    const talentRow = buildFormSelection(this.nationalitySelectionFormTarget, 'talent[row]')

    talentTable.setDataAtRowProp(talentRow, 'nationality_id', nationalityId)
    this.nationalitySelectionTarget.classList.remove('active')
  }

  submitDegreeSelection(event) {
    event.preventDefault()

    const degreeId = buildFormSelection(this.degreeSelectionFormTarget, 'talent[degree_id]')
    const talentRow = buildFormSelection(this.degreeSelectionFormTarget, 'talent[row]')

    talentTable.setDataAtRowProp(talentRow, 'degree_id', degreeId)
    this.degreeSelectionTarget.classList.remove('active')
  }

  submitGraduatedYearSelection(event) {
    event.preventDefault()

    const graduatedYear = buildFormSelection(this.graduatedYearSelectionFormTarget, 'talent[graduated_year]')
    const talentRow = buildFormSelection(this.graduatedYearSelectionFormTarget, 'talent[row]')

    talentTable.setDataAtRowProp(talentRow, 'graduated_year', graduatedYear)
    this.graduatedYearSelectionTarget.classList.remove('active')
  }

  submitLanguageSelection(event) {
    event.preventDefault()

    const languageIds = buildFormSelection(this.languageSelectionFormTarget, 'talent[language_ids]')
    const talentRow = buildFormSelection(this.languageSelectionFormTarget, 'talent[row]')

    talentTable.setDataAtRowProp(talentRow, 'language_ids', languageIds)
    this.languageSelectionTarget.classList.remove('active')
  }

  submitEnglishLevelSelection(event) {
    event.preventDefault()

    const englishLevelId = buildFormSelection(this.englishLevelSelectionFormTarget, 'talent[english_level_id]')
    const talentRow = buildFormSelection(this.englishLevelSelectionFormTarget, 'talent[row]')

    talentTable.setDataAtRowProp(talentRow, 'english_level_id', englishLevelId)
    this.englishLevelSelectionTarget.classList.remove('active')
  }

  submitCv(event) {
    event.preventDefault()

    const formData = new FormData(this.cvFormTarget)
    const cv = buildFormAttachment(this.cvFormTarget, 'talent[cv]')
    const cvReader = new FileReader()
    const talentRow = buildFormAttachment(this.cvFormTarget, 'talent[row]')

    if (cv instanceof File) {
      talentTable.setDataAtRowProp(talentRow, 'cv', cv.name)
      talentTable.setDataAtRowProp(talentRow, 'cv_type', cv.type)

      cvReader.onload = (e) => {
        talentTable.setDataAtRowProp(talentRow, 'cv_content', e.target.result)
      }

      cvReader.readAsDataURL(cv)
    }

    this.cvTarget.classList.remove('active')
  }

  submitAdditionalFile1(event) {
    event.preventDefault()

    const formData = new FormData(this.additionalFile1FormTarget)
    const additionalFile1 = buildFormAttachment(this.additionalFile1FormTarget, 'talent[additional_file1]')
    const additionalFile1Reader = new FileReader()
    const talentRow = buildFormAttachment(this.additionalFile1FormTarget, 'talent[row]')

    if (additionalFile1 instanceof File) {
      talentTable.setDataAtRowProp(talentRow, 'additional_file1', additionalFile1.name)
      talentTable.setDataAtRowProp(talentRow, 'additional_file1_type', additionalFile1.type)

      additionalFile1Reader.onload = (e) => {
        talentTable.setDataAtRowProp(talentRow, 'additional_file1_content', e.target.result)
      }

      additionalFile1Reader.readAsDataURL(additionalFile1)
    }

    this.additionalFile1Target.classList.remove('active')
  }

  submitAdditionalFile2(event) {
    event.preventDefault()

    const formData = new FormData(this.additionalFile2FormTarget)
    const additionalFile2 = buildFormAttachment(this.additionalFile2FormTarget, 'talent[additional_file2]')
    const additionalFile2Reader = new FileReader()
    const talentRow = buildFormAttachment(this.additionalFile2FormTarget, 'talent[row]')

    if (additionalFile2 instanceof File) {
      talentTable.setDataAtRowProp(talentRow, 'additional_file2', additionalFile2.name)
      talentTable.setDataAtRowProp(talentRow, 'additional_file2_type', additionalFile2.type)

      additionalFile2Reader.onload = (e) => {
        talentTable.setDataAtRowProp(talentRow, 'additional_file2_content', e.target.result)
      }

      additionalFile2Reader.readAsDataURL(additionalFile2)
    }

    this.additionalFile2Target.classList.remove('active')
  }

  mapData() {
    const talentTableData = talentTable.getData().map((row) => {
      const updatedSchema = { ...importTalentDataSchema }

      Object.keys(importTalentDataSchema).forEach((column, index) => {
        (updatedSchema[column] = row[index])
      })

      return updatedSchema
    })

    this.dataTarget.value = JSON.stringify(talentTableData)
  }

  submit(event) {
    event.preventDefault()
    this.mapData()

    event.target.parentNode.submit()
  }

  reset() {
    location.reload()
  }

  open(event) {
    event.preventDefault()

    validateTable(talentTable).then(isValid => {
      if (isValid) {
        new Modal(this.modalTarget).show()
      } else {
        this.mapData()
        this.formImportTarget.submit()
      }
    })
  }

  close() {
    Modal.getInstance(this.modalTarget).hide()
  }
}
