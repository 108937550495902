import {Controller} from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

// Connects to data-controller="selection-processes--inputs--select"
export default class extends Controller {
  static targets = ['selectStep', 'selectStepOptions', 'selectStatusContainer', 'selectStatus', 'btn']

  connect() {
    this.selectStepOptions()
    this.selectStatusOptions()
  }

  selectStepOptions() {
    if (!this.hasSelectStepTarget) return

    const that = this,
      inputCheckedOptions = this.selectStepTarget.querySelector('option:checked')

    new SlimSelect({
      select: this.selectStepTarget,
      settings: {
        contentLocation: this.selectStepOptionsTarget,
        contentPosition: 'relative',
        showSearch: false
      },
      events: {
        afterChange: (info) => {
          const value = info.shift().value

          that.loadSelectContainer(this.selectStepTarget, value)
          that.selectStatusContainer(value)
          that.loadBtn()
        }
      }
    })

    if (!inputCheckedOptions) return

    that.loadSelectContainer(this.selectStepTarget, inputCheckedOptions.value)
    that.selectStatusContainer(inputCheckedOptions.value)
  }

  selectStatusOptions() {
    if (!this.hasSelectStatusTarget) return

    this.selectStatusTargets.forEach((element) => {
      const that = this,
        inputCheckedOptions = element.querySelector('option:checked')

      new SlimSelect({
        select: element,
        settings: {
          showSearch: false
        },
        events: {
          afterChange: (info) => {
            that.loadBtn()
          }
        }
      })
    })
  }

  loadSelectContainer(input, value) {
    value.length && (input.parentNode.className = `select ${value}`)
  }

  loadBtn() {
    if (!this.hasBtnTarget) return

    this.btnTarget.classList.add('active')
  }

  selectStatusContainer(value) {
    this.selectStatusContainerTargets.forEach((element) => {
      element.classList.toggle('active', element.classList.contains(value))

      this.selectStatusTargets.forEach((ele) => {
        ele.parentNode.parentNode.classList.contains('active') ?
          ele.setAttribute('name', 'selection_process_comment[status]') :
          ele.removeAttribute('name')
      })
    })
  }
}
