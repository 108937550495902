import {Controller} from "@hotwired/stimulus"
import {driver} from 'driver.js';
import {DotLottie} from '@lottiefiles/dotlottie-web';

// Connects to data-controller="talents--tour--show"
export default class extends Controller {
  connect() {
    if (window.innerWidth > 769) {
      let isRestartTalent = JSON.parse(localStorage.getItem('is_retake_talent_tour') === 'true')

      if (parseInt(companyTalentCount) === 1) {
        this.wellDoneMessage()
      } else if (isRestartTalent) {
        this.wellDoneMessage()
      }
    }
  }

  wellDoneMessage() {
    const driverObjWellDone = driver({
      allowClose: false, onPopoverRender: (popover) => {
        popover.closeButton.style.display = ''

      }, doneBtnText: talentsTourTranslations.well_done.btn.next_tour, overlayOpacity: 0.6, steps: [{
        popover: {
          popoverClass: 'welldone-message',
          showButtons: ['close', 'next'],
          position: 'center',
          title: this.buildPopoverTitle(talentsTourTranslations.well_done.title, lottie),
          description: this.buildPopoverDescription(talentsTourTranslations.well_done.details, talentsTourTranslations.well_done.text)
        }
      }], onNextClick: () => {
        localStorage.setItem('is_retake_job_tour', false)
        localStorage.setItem('is_retake_talent_tour', false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjWellDone.destroy()
      },
      onCloseClick: () => {
        localStorage.setItem('is_retake_job_tour', false)
        localStorage.setItem('is_retake_talent_tour', false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjWellDone.destroy()
      },
    })

    driverObjWellDone.drive()
    this.animateLottie()
  }

  animateLottie() {
    let canvas = document.querySelector('#well-done-lottie')
    new DotLottie({
      canvas, src: canvas.getAttribute('src'), loop: false, autoplay: true
    });
  }

  buildPopoverTitle(title, lottie) {
    return `
      <div>
        <canvas id='well-done-lottie' src='${lottie}' style='width:150px; height:150px; margin:auto'></canvas>
        <h3>${title}</h3>
      </div>
    `
  }

  buildPopoverDescription(description, sub_description) {
    return `
     <div>
       <div class='details'>${description}</div>
       <div class='text'>${sub_description}</div> 
    </div>
`
  }

  checkList() {
    const targetController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='layouts--check-list']"),
      'layouts--check-list'
    )

    targetController.showCheckList()
  }
}
