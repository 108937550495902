import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="jobs--filters"
export default class extends Controller {
  static targets = ['container', 'content']

  toggle() {
    this.containerTarget.classList.toggle('active')
    this.contentTarget.classList.toggle('active')
  }
}
