import {Controller} from "@hotwired/stimulus"
import SlimSelect from 'slim-select'

// Connects to data-controller="companies--members--division-permission"
export default class extends Controller {
  static targets = ['checkbox', 'checkboxContainer', 'selectMultiple']

  connect() {
    this.toggle()
    this.selectMultipleOptions()
  }

  loadHiddenSelect(inputHidden, values) {
    inputHidden.setAttribute('value', values.join('-'))
  }

  toggle() {
    if (!this.hasCheckboxTarget) return

    const isChecked = this.checkboxTarget.checked

    this.checkboxContainerTarget.classList.toggle('active', isChecked)
    this.selectMultipleTarget.classList.toggle('disabled', isChecked)
    this.selectMultipleTarget.required = !isChecked
  }

  selectMultipleOptions() {
    if (!this.hasSelectMultipleTarget) return

    const that = this,
      inputHidden = this.selectMultipleTarget.parentNode.querySelector('input[type=hidden]'),
      inputCheckedOptions = this.selectMultipleTarget.querySelectorAll('option:checked'),
      inputName = this.selectMultipleTarget.dataset.name || this.selectMultipleTarget.name

    let select = new SlimSelect({
      select: this.selectMultipleTarget,
      settings: {
        placeholderText: companyMemberPlaceholderTranslations[`${inputName}`],
        searchPlaceholder: companyMemberPlaceholderTranslations.search,
        searchText: companyMemberPlaceholderTranslations.no_data_found,
        searchHighlight: true,
        closeOnSelect: false,
        openPosition: 'down'
      },
      events: {
        afterChange: (info) => {
          that.loadHiddenSelect(inputHidden, that.convertToArray(info))
        }
      }
    })

    this.checkboxTarget.addEventListener('change', function(event) {
      if (that.checkboxTarget.checked) {
        select.setSelected([])
      }
    })

    this.loadHiddenSelect(inputHidden, this.convertToArray(inputCheckedOptions))
    this.selectMultipleTarget.removeAttribute('name')
  }

  convertToArray(obj) {
    return Array.from(obj).map(obj => obj.value).sort((a, b) => {
      return a - b
    })
  }
}
