importTalentErrorMessages = checkWindowObject('importTalentErrorMessages')

function checkWindowObject(obj) { return typeof window[obj] !== 'undefined' && window[obj] }

function decodeHTMLEntities(text) {
  const parser = new DOMParser(), doc = parser.parseFromString(text, 'text/html')

  return doc.documentElement.textContent;
}

function propWithoutId(prop) {
  return prop.endsWith('_id') ? prop.replace('_id', '') : prop
}

function errorMessagesFiltered(row, prop) {
  return importTalentErrorMessages.filter((element) => {
    return element.index === row && (element.errors[prop] || element.errors[propWithoutId(prop)])
  }).pop()
}

const importTalentCells = function(row, col, prop) {
  const cellProperties = {}, errorMessages = errorMessagesFiltered(row, prop)

  if (errorMessages) {
    const value = errorMessages['errors'][prop] || errorMessages['errors'][propWithoutId(prop)]

    cellProperties.comment = { value: decodeHTMLEntities(value), readOnly: true }
    cellProperties.className = 'htInvalid'
  }

  return errorMessages ? cellProperties : {}
}

module.exports = { importTalentCells }
