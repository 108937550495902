import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="inputs--file"
export default class extends Controller {
  static targets = ['upload', 'uploadDescription', 'inputCache']

  toggleInputAttr(placeholder) {
    this.uploadTarget.classList.contains('error') && this.uploadTarget.classList.remove('error')

    const errorMessage = this.uploadTarget.querySelectorAll('.error-message')
    errorMessage.forEach((element) => element.remove())

    this.inputCacheTarget.removeAttribute('value')
    this.uploadDescriptionTarget.innerHTML = placeholder
  }

  change(event, placeholder, extensionWhiteList, wrongExtension) {
    if (!(event.target && this.uploadDescriptionTarget)) return

    this.toggleInputAttr(placeholder)

    if (!('files' in event.target && event.target.files.length)) return

    Array.from(event.target.files).forEach((file) => {
      const type = file.name.split('.').pop().toLowerCase()

      this.uploadDescriptionTarget.innerHTML = file.name

      if (extensionWhiteList.indexOf(type) === -1) {
        this.errorMessage(wrongExtension)
      } else {
        this.uploadDescriptionTarget.innerHTML = file.name
      }
    })
  }

  changeCv(event) {
    this.change(event, talentCvPlaceholder, talentCvExtensionWhiteList, talentCvWrongExtension)
  }

  changeAdditionalFile1(event) {
    this.change(event, talentAdditionalFile1Placeholder, talentAdditionalFile1ExtensionWhiteList,
      talentAdditionalFile1WrongExtension)
  }

  changeAdditionalFile2(event) {
    this.change(event, talentAdditionalFile2Placeholder, talentAdditionalFile2ExtensionWhiteList,
      talentAdditionalFile2WrongExtension)
  }

  errorMessage(message) {
    const errorMessage = this.uploadTarget.querySelectorAll('.error-message')

    this.uploadTarget.classList.add('error')
    errorMessage.length || this.uploadDescriptionTarget.insertAdjacentHTML('afterend', errorMessageContainer)
    this.uploadTarget.querySelector('.error-message').innerHTML = message
  }
}
