import {Controller} from "@hotwired/stimulus"
import {Modal} from 'bootstrap';

// Connects to data-controller="jobs--modal-leaving"
export default class extends Controller {
  static targets = ['modal']

  hasInteracted = false

  connect() {
    window.addEventListener("mousemove", this.detectInteraction)
    window.addEventListener("scroll", this.detectInteraction)

    this.addTurboBeforeVisit()
    this.addBeforeUnload()
  }

  addTurboBeforeVisit() {
    document.addEventListener("turbo:before-visit", (event) => {
      event.preventDefault()
      new Modal(this.modalTarget).show();

      this.hasInteracted = false
      this.changePage = event.detail.url
    })
  }

  addBeforeUnload() {
    window.addEventListener("beforeunload", (event) => {
      if (this.hasInteracted) {
        event.preventDefault()
        event.returnValue = ''
      }
    })
  }

  setValue() {
    this.hasInteracted = false;
  }

  detectInteraction = () => {
    this.hasInteracted = true;
    window.removeEventListener("mousemove", this.detectInteraction);
    window.removeEventListener("scroll", this.detectInteraction);
  }

  open() {
    new Modal(this.modalTarget).show();
  }

  close() {
    this.changePage = window.location.href
    this.hasInteracted = true
    Modal.getInstance(this.modalTarget).hide();
  }

  change() {
    window.location = this.changePage;
  }
}
