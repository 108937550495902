import {Controller} from "@hotwired/stimulus"
import SlimSelect from 'slim-select'
import SmoothScroll from 'smooth-scroll'

let smoothScroll = {
  headerSelector: 'header nav',
  speed: 600,
  duration: 900
}

// Connects to data-controller="talents--inputs--select"
export default class extends Controller {
  static targets = ['select', 'selectMultiple', 'selectActiveJob', 'selectActiveJobOption',
    'selectActiveJobMultiple', 'selectCompanyMemberMultiple']

  connect() {
    this.selectOptions()
    this.selectMultipleOptions()
    this.selectActiveJobOptions()
    this.selectActiveJobMultipleOptions()
    this.selectCompanyMemberMultipleOptions()
    this.loadSelectJob()
  }

  loadHiddenSelect(inputHidden, values) {
    inputHidden.setAttribute('value', values.join('-'))
  }

  selectOptions() {
    if (!this.hasSelectTarget) return

    this.selectTargets.forEach((element) => {
      const that = this,
        inputCheckedOptions = element.querySelector('option:checked')

      new SlimSelect({
        select: element,
        settings: {
          searchPlaceholder: talentSearchFormPlaceholderTranslations.search,
          searchText: talentSearchFormPlaceholderTranslations.no_data_found,
          searchHighlight: true,
          openPosition: 'down'
        }
      })
    })
  }

  selectMultipleOptions() {
    if (!this.hasSelectMultipleTarget) return

    this.selectMultipleTargets.forEach((element) => {
      const that = this,
        inputHidden = element.parentNode.querySelector('input[type=hidden]'),
        inputCheckedOptions = element.querySelectorAll('option:checked'),
        inputName = element.dataset.name || element.name

      new SlimSelect({
        select: element,
        settings: {
          placeholderText: talentSearchFormPlaceholderTranslations[`${inputName}`],
          searchPlaceholder: talentSearchFormPlaceholderTranslations.active_jobs.search,
          searchText: talentSearchFormPlaceholderTranslations.no_data_found,
          searchHighlight: true,
          openPosition: 'down'
        },
        events: {
          afterChange: (info) => {
            that.loadHiddenSelect(inputHidden, that.convertToArray(info))
          }
        }
      })

      this.loadHiddenSelect(inputHidden, this.convertToArray(inputCheckedOptions))
      element.removeAttribute('name')
    })
  }

  selectActiveJobOptions() {
    if (!this.hasSelectActiveJobTarget) return

    const that = this

    new SlimSelect({
      select: that.selectActiveJobTarget,
      settings: {
        contentLocation: that.selectActiveJobOptionTarget,
        contentPosition: 'relative',
        searchPlaceholder: talentSearchFormPlaceholderTranslations.active_jobs.search,
        searchText: talentSearchFormPlaceholderTranslations.no_data_found,
        searchHighlight: true,
        openPosition: 'down'
      },
      events: {
        afterChange: (info) => {
          info.shift().text && that.element.classList.add('selecting')
        }
      }
    })
  }

  selectActiveJobMultipleOptions() {
    if (!this.hasSelectActiveJobMultipleTarget) return

    this.selectActiveJobMultipleTargets.forEach((element) => {
      const that = this,
        inputHidden = element.parentNode.querySelector('input[type=hidden]'),
        inputCheckedOptions = element.querySelectorAll('option:checked'),
        inputName = element.dataset.name || element.name

      const select = new SlimSelect({
        select: element,
        settings: {
          placeholderText: talentSearchFormPlaceholderTranslations[`${inputName}`],
          searchPlaceholder: talentSearchFormPlaceholderTranslations.active_jobs.search,
          searchText: talentSearchFormPlaceholderTranslations.no_data_found,
          searchHighlight: true,
          openPosition: 'down'
        },
        events: {
          afterChange: (info) => {
            that.loadHiddenSelect(inputHidden, that.convertToArray(info))
          }
        }
      })

      this.loadHiddenSelect(inputHidden, this.convertToArray(inputCheckedOptions))
      element.removeAttribute('name')
    })
  }

  selectCompanyMemberMultipleOptions() {
    if (!this.hasSelectCompanyMemberMultipleTarget) return

    this.selectCompanyMemberMultipleTargets.forEach((element) => {
      const that = this,
        inputHidden = element.parentNode.querySelector('input[type=hidden]'),
        inputCheckedOptions = element.querySelectorAll('option:checked'),
        inputName = element.dataset.name || element.name

      const select = new SlimSelect({
        select: element,
        settings: {
          placeholderText: talentSearchFormPlaceholderTranslations[`${inputName}`],
          searchPlaceholder: talentSearchFormPlaceholderTranslations.company_members.search,
          searchText: talentSearchFormPlaceholderTranslations.company_members.no_data_found,
          searchHighlight: true,
          openPosition: 'down'
        },
        events: {
          afterChange: (info) => {
            that.loadHiddenSelect(inputHidden, that.convertToArray(info))
          }
        }
      })

      this.loadHiddenSelect(inputHidden, this.convertToArray(inputCheckedOptions))
      element.removeAttribute('name')
    })
  }

  convertToArray(obj) {
    return Array.from(obj).map(obj => obj.value).sort((a, b) => {
      return a - b
    })
  }

  loadSelectJob() {
    const talentClassName = document.getElementById('talents') && document.getElementById('talents').className

    if (!(location.hash == '#select_job' && talentClassName === 'edit')) return

    const that = this,
      selectJobOriginal = this.element.querySelector('#talent_active_job_id'),
      selectJobGenerated = this.element.querySelector('.ss-main')

    if (!(selectJobOriginal && selectJobGenerated)) return

    setTimeout(function() {
      new SmoothScroll().animateScroll(that.element.parentElement, null, {
        header: smoothScroll.headerSelector,
        speed: smoothScroll.speed,
        speedAsDuration: true,
        updateURL: false
      })

      selectJobGenerated.click()
    }, smoothScroll.duration)
  }
}
