import {Controller} from "@hotwired/stimulus"
import Flicking from '@egjs/flicking';
import {AutoPlay, Pagination} from '@egjs/flicking-plugins';

// Connects to data-controller="home--workflow-sliders"
export default class extends Controller {
  static targets = ['prevBtn', 'nextBtn', 'controlItem']
  connect() {
    const that = this,
      flicking = new Flicking("#flicking-benefits", {
        circular: true,
        align: 'prev',
      });

    flicking.addPlugins(new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: false }));
    flicking.addPlugins(new Pagination(
      {
        parentEl: this.element,
        selector: ".flicking-pagination",
        type: "bullet"
      }));

    flicking.on('changed', function(event) {
      that.toggleControl(event.panel.element.children[0].classList[1])
    })


    this.controlItemTargets.forEach((element) => {
      element.addEventListener('click', function(event) {
        flicking.moveTo(that.controlItemTargets.indexOf(element))

        that.toggleControl(element.className)
      })
    })
  }

  toggleControl(className) {
    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })
  }
}
