import {Controller} from "@hotwired/stimulus"
import Ripple from 'ripple-effects'

let colors = {
  redTorch: '#1e272e'
}

// Connects to data-controller="companies--information--tabs"
export default class extends Controller {
  static targets = ['controlItem', 'containerItem']

  initialize() {
    this.loaded()
  }

  loaded() {
    this.load()
  }

  load() {
    const companyInformationClassNames = document.getElementById('company-information').className.split(' '),
      companyInformationClassName = companyInformationClassNames.shift()

    if (companyInformationClassName === 'index') return

    this.toggleControl(companyInformationClassName)
    this.toggleContainer(companyInformationClassName)
  }

  resizing() {
    this.resize()
  }

  toggleControl(className) {
    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })
  }

  toggleContainer(className) {
    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        const containerItemHeight = Math.ceil(element.querySelector('.div').getBoundingClientRect().height)

        element.classList.add('active')
        element.style.height = `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })
  }

  click(event) {
    event.preventDefault()

    Ripple(event.target, { background: colors.blackPearl, opacity: 0.1 })

    const ActiveTabClassName = [].slice.apply(event.target.closest('li').classList).pop()

    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })

    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        const containerItemHeight = Math.ceil(element.querySelector('.div').getBoundingClientRect().height)

        element.classList.add('active')
        element.style.height = `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })
  }

  resize() {
    this.containerItemTargets.forEach((element) => {
      element.classList.contains('active') && (element.style.height = 'auto')
    })
  }
}
