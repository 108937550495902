importJobErrorMessages = checkWindowObject('importJobErrorMessages')

function checkWindowObject(obj) { return typeof window[obj] !== 'undefined' && window[obj] }

function decodeHTMLEntities(text) {
  const parser = new DOMParser(), doc = parser.parseFromString(text, 'text/html')

  return doc.documentElement.textContent;
}

const importJobCells = function(row, col, prop) {
  const cellProperties = {},
    errorMessages = importJobErrorMessages.filter((element) => {
      return element.index === row && element.errors[prop]
    }).pop()

  if (errorMessages) {
    cellProperties.comment = { value: decodeHTMLEntities(errorMessages['errors'][prop]), readOnly: true }
    cellProperties.className = 'htInvalid'
  }

  return errorMessages ? cellProperties : {}
}

module.exports = { importJobCells }
