import { Controller } from "@hotwired/stimulus"
import { gsap } from "gsap";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin);

// Connects to data-controller="home--scrolling"
export default class extends Controller {

  scroll(){
    gsap.to(window, {duration: 2, scrollTo: '#start-today'});
  }
}
