import { Controller } from "@hotwired/stimulus"
import autoComplete from '@tarekraafat/autocomplete.js'
import { sprintf } from 'sprintf-js'

// Connects to data-controller="jobs--inputs--text"
export default class extends Controller {
  static targets = ['keyword']

  connect() {
    this.autoComplete()
  }

  autoComplete() {
    if (!this.hasKeywordTarget) return

    const
      that = this,
      inputAutoComplete = new autoComplete({
        selector: () => that.keywordTarget,
        data: {
          src: async () => {
            const
              params = new URLSearchParams({
                keyword: that.keywordTarget.value,
              }).toString(),
              source = await fetch(`${searchJobAutocompleteUrl}?${params}`),
              data = await source.json()

            return data.keywords
          },
          cache: false,
        },
        resultsList: {
          element: (list, data) => {
            const info = document.createElement('p')

            if (data.results.length < 1) {
              info.innerHTML = sprintf(searchJobAutocompleteTranslations.results_html, data.matches.length, data.query)
              info.classList.add('active')
            } else {
              info.classList.contains('active') && info.classList.remove('active')
            }

            list.prepend(info)
          },
          noResults: true,
          maxResults: 10
        },
        resultItem: {
          highlight: true
        },
        events: {
          input: {
            focus: () => {
              inputAutoComplete.input.value.length && inputAutoComplete.start()
            },
            keydown: (event) => {
              switch (event.key) {
                case 'Enter':
                  return

                case 'ArrowRight':
                  inputAutoComplete.select()
                  break

                case 'ArrowUp':
                  inputAutoComplete.previous()
                  break

                case 'ArrowDown':
                  inputAutoComplete.next()
                  break
              }
            }
          }
        }
      })

    this.keywordTarget.addEventListener('selection', function(event) {
      that.keywordTarget.value = event.detail.selection.value
    })
  }
}
