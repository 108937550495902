import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="talents--form-task"
export default class extends Controller {
  static targets = ['tasksContainer', 'btn']

  connect() {
    this.events()
  }

  toggle() {
    this.tasksContainerTarget.classList.toggle('active')
  }

  close() {
    this.tasksContainerTarget.classList.remove('active')
  }

  clickOutsideType() {
    if (!this.hasBtnTarget) return

    const that = this

    document.addEventListener('click', function(event) {
      const btnTypeAround = that.btnTarget.contains(event.target),
        containerTypeAround = that.tasksContainerTarget.contains(event.target),
        btnTypeVisible = window.getComputedStyle(that.btnTarget).visibility !== 'hidden',
        containerTypeVisible = window.getComputedStyle(that.tasksContainerTarget).visibility !== 'hidden',
        leftClicked = (event.button === 1)

      !containerTypeAround && containerTypeVisible && !btnTypeAround && btnTypeVisible && leftClicked && that.close()
    })
  }

  events() {
    this.clickOutsideType()
  }
}
