import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

// Connects to data-controller="selection-processes--inputs--filter-select"
export default class extends Controller {
  static targets = ['radioDisplay', 'selectStep', 'selectStepOptions', 'selectStatusContainer', 'selectStatus']

  connect() {
    this.selectStepOptions()
    this.selectStatusOptions()
  }

  selectStepOptions() {
    if (!this.hasSelectStepTarget) return

    const that = this,
      inputCheckedOptions = this.selectStepTarget.querySelector('option:checked')

    new SlimSelect({
      select: this.selectStepTarget,
      settings: {
        contentLocation: this.selectStepOptionsTarget,
        contentPosition: 'relative',
        showSearch: false,
        openPosition: 'down'
      },
      events: {
        afterChange: (info) => {
          const value = info.shift().value

          that.loadSelectContainer(this.selectStepTarget, value)
          that.loadRadioDisplay(value)
          that.selectStatusContainer(value)
        }
      }
    })

    if (!inputCheckedOptions) return

    that.loadSelectContainer(this.selectStepTarget, inputCheckedOptions.value)
    that.selectStatusContainer(inputCheckedOptions.value)
  }

  selectStatusOptions() {
    if (!this.hasSelectStatusTarget) return

    this.selectStatusTargets.forEach((element) => {
      const that = this,
        inputCheckedOptions = element.querySelector('option:checked')

      new SlimSelect({
        select: element,
        settings: {
          showSearch: false,
          openPosition: 'down'
        }
      })
    })
  }

  loadSelectContainer(input, value) {
    input.parentNode.className = `select ${value.length ? value : 'blank'}`
  }

  loadRadioDisplay(value) {
    const targetName = value ? (value === 'closed' ? 'close' : 'open') : 'all',
      targetElement = this.radioDisplayTargets.filter((element) => element.value === targetName).pop()

    targetElement && (targetElement.checked = true)
  }

  selectStatusContainer(value) {
    this.selectStatusContainerTargets.forEach((element) => {
      const currentStatus = value || 'disabled'

      element.classList.toggle('active', element.classList.contains(currentStatus))

      this.selectStatusTargets.forEach((ele) => {
        ele.parentNode.parentNode.classList.contains('active') ?
          ele.setAttribute('name', 'status') :
          ele.removeAttribute('name')
      })
    })
  }

  radioChange() {
    const inputCheckedOptions = this.selectStepTarget.querySelector('option:checked'),
      deSelectButton = this.element.querySelector('.ss-deselect'),
      targetElement = this.radioDisplayTargets.filter((element) => element.checked === true).pop()

    if (!inputCheckedOptions.value) return

    deSelectButton.click()
    targetElement.checked = true
  }
}
