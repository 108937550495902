import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="home--faq"
export default class extends Controller {
  static targets = ['parent', 'childList', 'childInnerList']

  connect() {
    this.events()
  }

  toggle() {
    this.parentTarget.classList.toggle('active')

    this.childListTarget.style.height =
      this.parentTarget.classList.contains('active') ? `${this.childInnerListTarget.clientHeight}px` : null
  }

  close() {
    this.parentTarget.classList.remove('active')
    this.childListTarget.style.height = null
  }

  clickOutside() {
    const that = this

    document.addEventListener('click', function (event) {
      const containerShowAround = that.parentTarget.contains(event.target),
        containerShowVisible = window.getComputedStyle(that.parentTarget).visibility !== 'hidden',
        leftClicked = (event.button === 1)

      !containerShowAround && containerShowVisible && leftClicked && that.close()
    })
  }

  events() {
    this.clickOutside()
  }
}
