import { Controller } from "@hotwired/stimulus"
import Ripple from 'ripple-effects'

let load = {
    delayDuration: 600,
    timer: null
  },
  colors = {
    blackPearl: '#1e272e'
  }

// Connects to data-controller="talents--tabs"
export default class extends Controller {
  static targets = ['controlItem', 'containerItem']

  initialize() {
    this.loaded()
  }

  loaded() {
    this.loadShow()
  }

  loadShow() {
    const talentClassName = document.getElementById('talents').className

    if (talentClassName === 'show') return

    const that = this,
      isActiveProcessTab = talentClassName === 'active-process'

    clearTimeout(load.timer)

    load.timer = setTimeout(() => {
      that.toggleControl(talentClassName)
      that.toggleContainer(talentClassName)
      that.toggleActiveProcessForm(isActiveProcessTab)
    }, load.delayDuration)
  }

  resizing() {
    this.resize()
  }

  toggleControl(className) {
    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })
  }

  toggleContainer(className) {
    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        const containerItemHeight = Math.ceil(element.querySelector('.div').getBoundingClientRect().height),
          hasTodoClass = element.classList.contains('todo')

        element.classList.add('active')
        element.style.height = hasTodoClass ? 'auto' : `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })
  }

  click(event) {
    event.preventDefault()

    Ripple(event.target, { background: colors.blackPearl, opacity: 0.1 })

    const
      ActiveTabClassName = [].slice.apply(event.target.closest('li').classList).pop(),
      isActiveProcessTab = ActiveTabClassName === 'active-process'

    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })

    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        const containerItemHeight = Math.ceil(element.querySelector('.div').getBoundingClientRect().height),
          hasTodoClass = element.classList.contains('todo')

        element.classList.add('active')
        element.style.height = hasTodoClass ? 'auto' : `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })

    this.toggleActiveProcessForm(isActiveProcessTab)
  }

  toggleActiveProcessForm(state) {
    const talentActiveProcessForm = document.getElementById('talent-active-process-form')

    if (!talentActiveProcessForm) return

    talentActiveProcessForm.classList.toggle('hide', !state)
    state && this.loadActiveProcessFormBottom(talentActiveProcessForm)
  }

  loadActiveProcessFormBottom(element) {
    setTimeout(function() {
      const footerTop = document.querySelector('footer').offsetTop,
        bottom = window.innerHeight - footerTop

      element.style.bottom = bottom > 0 ? `${bottom}px` : null
    }, load.delayDuration)
  }

  resize() {
    this.containerItemTargets.forEach((element) => {
      element.classList.contains('active') && (element.style.height = 'auto')
    })
  }
}
