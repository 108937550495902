let validatorRegExp = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  working_location: /^[0-9]+(-[0-9]+)*$/,
  url: /^(https?:\/\/)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+([\/?].*)?$/
}

let presence = (value) => { return value !== '' }
let email = (value) => { return value === null || validatorRegExp.email.test(value) }
let emailNotDuplicate = (value, duplicateEmails) => { return value === null || duplicateEmails.length === 0 }
let phoneNumber = (value) => { return value === null || value.length <= 15 }
let salary = (value) => { return value === null || value < 2000000000 }
let salaryMax = (max, min) => { return max === null || max >= min }
let workingLocation = (value) => { return value === null || validatorRegExp.working_location.test(value) }
let stringLength = (value) => { return value === null || value.length <= 255 }
let textLength = (value) => { return value === null || value.length <= 2000 }
let url = (value) => { return value === null || validatorRegExp.url.test(value) }

const fullNameValidator = function(value, callback) {
  callback(presence(value) && stringLength(value))
}

const titleValidator = function(value, callback) {
  callback(presence(value) && stringLength(value))
}

const emailValidator = function(value, callback) {
  const emails = this.instance.getDataAtCol(this.col)
  const duplicateEmails = emails.filter((email) => email !== null && email !== '' && email === value)

  callback(presence(value) && email(value) && emailNotDuplicate(value, duplicateEmails))
}

const phoneNumberLengthValidator = function(value, callback) {
  callback(phoneNumber(value))
}

const salaryValidator = function(value, callback) {
  callback(salary(value))
}

const salaryMaxValidator = function(value, callback) {
  const rowData = this.instance.getSourceDataAtRow(this.row)
  const salaryMin = (rowData && rowData.salary_min) ? rowData.salary_min : 0

  callback(salaryMax(value, salaryMin))
}

const workingLocationValidator = function(value, callback) {
  callback(workingLocation(value))
}

const stringLengthValidator = function(value, callback) {
  callback(stringLength(value))
}

const textLengthValidator = function(value, callback) {
  callback(textLength(value))
}

const urlValidator = function(value, callback) {
  callback(url(value) && stringLength(value))
}

module.exports = { fullNameValidator, titleValidator, emailValidator, phoneNumberLengthValidator,
  salaryValidator, salaryMaxValidator, workingLocationValidator, stringLengthValidator, textLengthValidator, urlValidator }
