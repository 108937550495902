import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

// Connects to data-controller="talents--inputs--select-active-job"
export default class extends Controller {
  static targets = ['btn', 'title', 'formContainer', 'formInner', 'select', 'selectOptions']

  connect() {
    this.selectOptions()
  }

  toggle(event) {
    event.preventDefault()

    if (!this.hasFormContainerTarget) return

    this.formContainerTarget.classList.toggle('active')
    this.formContainerTarget.style.height =
      this.formContainerTarget.classList.contains('active') ? `${this.formInnerTarget.clientHeight}px` : null
  }

  selectOptions() {
    const
      that = this,
      titleWidth = this.hasTitleTarget && this.titleTarget.offsetWidth

    new SlimSelect({
      select: that.selectTarget,
      settings: {
        alwaysOpen: true,
        contentLocation: this.selectOptionsTarget,
        contentPosition: 'relative',
        searchPlaceholder: jobSearchFormPlaceholderTranslations.active_jobs.search,
        searchText: jobSearchFormPlaceholderTranslations.active_jobs.no_data_found,
        searchHighlight: true,
        openPosition: 'down'
      },
      events: {
        afterChange: (info) => {
          const text = info.shift().text

          if (!(this.hasTitleTarget && text)) return

          that.element.classList.add('selecting')
          that.titleTarget.innerHTML = text
          that.titleTarget.style.width = `${titleWidth}px`
        }
      }
    })
  }
}
