import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

// Connects to data-controller="companies--applications--select"
export default class extends Controller {
  static targets = ['select']

  connect() {
    this.selectOptions()
  }

  selectOptions() {
    if (!this.hasSelectTarget) return

    this.selectTargets.forEach((element) => {
      const that = this,
        inputCheckedOptions = element.querySelector('option:checked')

      new SlimSelect({
        select: element,
        settings: {
          showSearch: false,
          openPosition: 'down'
        }
      })
    })
  }
}
