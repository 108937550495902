import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="talents--image"
export default class extends Controller {
  static targets = ['display', 'displayImage', 'upload', 'uploadDescription', 'inputCache']

  toggle() {
    this.toggleDisplayAttr(true)
  }

  toggleDisplayAttr(state) {
    this.displayTarget.classList.toggle('lazy-loading', state)
    this.displayImageTarget.classList.toggle('lazy', state)
  }

  toggleInputAttr() {
    this.uploadTarget.classList.contains('error') && this.uploadTarget.classList.remove('error')

    const errorMessage = this.uploadTarget.querySelectorAll('.error-message')
    errorMessage.forEach((element) => element.remove())

    this.inputCacheTarget.removeAttribute('value')
    this.uploadDescriptionTarget.innerHTML = talentImagePlaceholder
  }

  change(event) {
    if (!(this.displayImageTarget && event.target && this.uploadDescriptionTarget)) return

    this.toggleInputAttr()

    if (!('files' in event.target && event.target.files.length)) return

    Array.from(event.target.files).forEach((file) => {
      const type = file.name.split('.').pop().toLowerCase()

      this.toggleDisplayAttr(false)

      this.uploadDescriptionTarget.innerHTML = file.name

      if (talentImageExtensionWhiteList.indexOf(type) === -1) {
        this.errorMessage(talentImageWrongExtension)
      } else if (file.size > talentImageSizeRange) {
        this.errorMessage(talentImageSizeLimit)
      } else {
        this.displayImageTarget.removeAttribute('srcset')
        this.displayImageTarget.setAttribute('src', URL.createObjectURL(file))
      }
    })
  }

  errorMessage(message) {
    const errorMessage = this.uploadTarget.querySelectorAll('.error-message')

    this.uploadTarget.classList.add('error')
    errorMessage.length || this.uploadDescriptionTarget.insertAdjacentHTML('afterend', errorMessageContainer)
    this.uploadTarget.querySelector('.error-message').innerHTML = message
  }
}
