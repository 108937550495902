currentCompanyMemberIsAdmin = checkWindowObject('currentCompanyMemberIsAdmin')

function checkWindowObject(obj) { return typeof window[obj] !== 'undefined' && window[obj] }

function closeOtherSelection(otherSelectionContainer) {
  otherSelectionContainer.forEach((element) => {
    if (element.classList.contains('active')) { element.classList.remove('active') }
  })
}

function assignSelectionRow(selectionContainer, row) {
  const talentRow = selectionContainer.querySelector('input[name="talent[row]"]')

  talentRow && (talentRow.value = row)
}

function deSelect(selectionContainer) {
  const ssDeselect = selectionContainer.querySelector('.ss-deselect')

  ssDeselect && ssDeselect.click()
}

const handleCellMouseDown = function(table, selectionContainer, otherSelectionContainer, propName) {
  table.addHook('afterOnCellMouseDown', (event, coords, td) => {
    if (event.button !== 0) return

    const prop = table.colToProp(coords.col)

    if (prop === propName) {
      closeOtherSelection(otherSelectionContainer)
      selectionContainer.classList.add('active')

      assignSelectionRow(selectionContainer, coords.row)
      deSelect(selectionContainer)
    }
  })
}

const activeJobSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'active_job_id')
}

const companyMemberSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'company_member_id')
}

const genderSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'gender')
}

const nationalitySelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'nationality_id')
}

const degreeSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'degree_id')
}

const graduatedYearSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'graduated_year')
}

const languageSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'language_ids')
}

const englishLevelSelection = function(table, selectionContainer, otherSelectionContainer) {
  handleCellMouseDown(table, selectionContainer, otherSelectionContainer, 'english_level_id')
}

module.exports = { activeJobSelection, companyMemberSelection, genderSelection, nationalitySelection, degreeSelection,
  graduatedYearSelection, languageSelection, englishLevelSelection }
