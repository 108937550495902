import {Controller} from "@hotwired/stimulus"
import {Modal} from "bootstrap";

// Connects to data-controller="companies--modal-confirmation"
export default class extends Controller {
  static targets = ['modal']

  open(event) {
    event.preventDefault()

    new Modal(this.modalTarget).show()
  }

  submit() {
    Modal.getInstance(this.modalTarget).hide()
  }

  close(event) {
    event.preventDefault()

    Modal.getInstance(this.modalTarget).hide()
  }
}
