import {Controller} from "@hotwired/stimulus"
import Handsontable from 'handsontable'
import {importJobColumns} from './import_jobs/columns'
import {importJobCells} from './import_jobs/cells'
import {importStatusRenderer} from './base/import_status_renderer'
import {
  divisionSelection, employmentTypeSelection, locationSelection, englishLevelSelection,
  workingHourStartSelection, workingHourEndSelection
} from './import_jobs/selections'

import {
  titleValidator, salaryMaxValidator, workingLocationValidator,
  stringLengthValidator, textLengthValidator
} from '../transfer/base/validators'

import {Modal} from 'bootstrap'

function replaceBreakTags(text) {
  return text.replace(/<br\s*\/?>/gi, '\n')
}

function decodeHTMLEntities(text) {
  const parser = new DOMParser(), doc = parser.parseFromString(text, 'text/html');

  return replaceBreakTags(doc.documentElement.textContent)
}

function buildFormSelection(formTarget, fieldName) {
  const formData = new FormData(formTarget)
  const serializedData = Object.fromEntries(formData.entries())

  return serializedData[fieldName]
}

function validateTable(jobTable) {
  return new Promise((resolve) => {
    jobTable.validateCells(function(valid) {
      resolve(valid);
    });
  });
}

let jobTable = {}, minSpareRows = 1, initializeRows = 5, targetHeaderCols = [1]

// Connects to data-controller="transfer--import-jobs"
export default class extends Controller {
  static targets = ['table', 'divisionSelection', 'employmentTypeSelection', 'locationSelection', 'englishLevelSelection',
    'workingHourStartSelection', 'workingHourEndSelection', 'divisionSelectionForm', 'employmentTypeSelectionForm', 'locationSelectionForm',
    'englishLevelSelectionForm', 'workingHourStartSelectionForm', 'workingHourEndSelectionForm', 'data', 'modal',
    'formImport']

  connect() {
    this.regisRenderers()
    this.registerValidators()
    this.loadJobData()
    this.loadTable()
    this.loadSelections()
  }

  regisRenderers() {
    Handsontable.renderers.registerRenderer('importStatusRenderer', importStatusRenderer)
  }

  registerValidators() {
    Handsontable.validators.registerValidator('titleValidator', titleValidator)
    Handsontable.validators.registerValidator('salaryMaxValidator', salaryMaxValidator)
    Handsontable.validators.registerValidator('workingLocationValidator', workingLocationValidator)
    Handsontable.validators.registerValidator('stringLengthValidator', stringLengthValidator)
    Handsontable.validators.registerValidator('textLengthValidator', textLengthValidator)
  }

  loadJobData() {
    if (importJobData.length > 0) {
      importJobData.map((job) => {
        return Object.keys(job).forEach((attr) => {
          if (typeof job[attr] === 'string') {
            (job[attr] = decodeHTMLEntities(job[attr]))
          }
        })
      })
    } else {
      importJobData = Array(initializeRows).fill().map(() => Object.assign({}, importJobDataSchema))
    }
  }

  loadTable() {
    jobTable = new Handsontable(this.tableTarget, {
      data: importJobData,
      dataSchema: importJobDataSchema,
      colHeaders: importJobColHeaders,
      rowHeaders: true,
      columns: importJobColumns,
      cells: importJobCells,
      height: 'auto',
      width: 'auto',
      autoWrapRow: true,
      autoWrapCol: true,
      contextMenu: ['row_below', 'remove_row', 'clear_column'],
      minSpareRows: minSpareRows,
      maxRows: importJobLimit,
      comments: true,
      afterGetColHeader: function(col, th) {
        if (targetHeaderCols.includes(col)) { th.classList.add('required') }
      },
      licenseKey: 'non-commercial-and-evaluation',
    })

    jobTable.getData().length > minSpareRows && jobTable.validateCells()
  }

  loadSelections() {
    const otherSelectionContainer = this.element.querySelectorAll('.import-selection')

    divisionSelection(jobTable, this.divisionSelectionTarget, otherSelectionContainer)
    employmentTypeSelection(jobTable, this.employmentTypeSelectionTarget, otherSelectionContainer)
    locationSelection(jobTable, this.locationSelectionTarget, otherSelectionContainer)
    englishLevelSelection(jobTable, this.englishLevelSelectionTarget, otherSelectionContainer)
    workingHourStartSelection(jobTable, this.workingHourStartSelectionTarget, otherSelectionContainer)
    workingHourEndSelection(jobTable, this.workingHourEndSelectionTarget, otherSelectionContainer)
  }

  toggleDivisionSelection() {
    this.divisionSelectionTarget.classList.toggle('active')
  }

  toggleEmploymentTypeSelection() {
    this.employmentTypeSelectionTarget.classList.toggle('active')
  }

  toggleLocationSelection() {
    this.locationSelectionTarget.classList.toggle('active')
  }

  toggleEnglishLevelSelection() {
    this.englishLevelSelectionTarget.classList.toggle('active')
  }

  toggleWorkingHourStartSelection() {
    this.workingHourStartSelectionTarget.classList.toggle('active')
  }

  toggleWorkingHourEndSelection() {
    this.workingHourEndSelectionTarget.classList.toggle('active')
  }

  submitDivisionSelection(event) {
    event.preventDefault()

    const divisionId = buildFormSelection(this.divisionSelectionFormTarget, 'job[division_id]')
    const jobRow = buildFormSelection(this.divisionSelectionFormTarget, 'job[row]')

    jobTable.setDataAtRowProp(jobRow, 'division_id', divisionId)
    this.divisionSelectionTarget.classList.remove('active')
  }

  submitEmploymentTypeSelection(event) {
    event.preventDefault()

    const employmentTypeId = buildFormSelection(this.employmentTypeSelectionFormTarget, 'job[employment_type]')
    const jobRow = buildFormSelection(this.employmentTypeSelectionFormTarget, 'job[row]')

    jobTable.setDataAtRowProp(jobRow, 'employment_type', employmentTypeId)
    this.employmentTypeSelectionTarget.classList.remove('active')
  }

  submitLocationSelection(event) {
    event.preventDefault()

    const locationIds = buildFormSelection(this.locationSelectionFormTarget, 'job[working_location_ids]')
    const jobRow = buildFormSelection(this.locationSelectionFormTarget, 'job[row]')

    jobTable.setDataAtRowProp(jobRow, 'working_location_ids', locationIds)
    this.locationSelectionTarget.classList.remove('active')
  }

  submitEnglishLevelSelection(event) {
    event.preventDefault()

    const englishLevelId = buildFormSelection(this.englishLevelSelectionFormTarget, 'job[english_level_id]')
    const jobRow = buildFormSelection(this.englishLevelSelectionFormTarget, 'job[row]')

    jobTable.setDataAtRowProp(jobRow, 'english_level_id', englishLevelId)
    this.englishLevelSelectionTarget.classList.remove('active')
  }

  submitWorkingHourStartSelection(event) {
    event.preventDefault()

    const workingHourStart = buildFormSelection(this.workingHourStartSelectionFormTarget, 'job[working_hour_start(5i)]')
    const jobRow = buildFormSelection(this.workingHourStartSelectionFormTarget, 'job[row]')

    jobTable.setDataAtRowProp(jobRow, 'working_hour_start', workingHourStart)
    this.workingHourStartSelectionTarget.classList.remove('active')
  }

  submitWorkingHourEndSelection(event) {
    event.preventDefault()

    const workingHourEnd = buildFormSelection(this.workingHourEndSelectionFormTarget, 'job[working_hour_end(5i)]')
    const jobRow = buildFormSelection(this.workingHourEndSelectionFormTarget, 'job[row]')

    jobTable.setDataAtRowProp(jobRow, 'working_hour_end', workingHourEnd)
    this.workingHourEndSelectionTarget.classList.remove('active')
  }

  mapData() {
    const jobTableData = jobTable.getData().map((row) => {
      const updatedSchema = { ...importJobDataSchema }

      Object.keys(importJobDataSchema).forEach((column, index) => {
        (updatedSchema[column] = row[index])
      })

      return updatedSchema
    })

    this.dataTarget.value = JSON.stringify(jobTableData)

  }

  submit(event) {
    event.preventDefault()
    this.mapData()

    event.target.parentNode.submit()
  }

  reset() {
    location.reload()
  }

  open(event) {
    event.preventDefault()

    validateTable(jobTable).then(isValid => {
      if (isValid) {
        new Modal(this.modalTarget).show()
      } else {
        this.mapData()
        this.formImportTarget.submit()
      }
    })
  }

  close() {
    Modal.getInstance(this.modalTarget).hide()
  }
}
