import { Controller } from "@hotwired/stimulus"
import { Modal } from 'bootstrap';

// Connects to data-controller="companies--modal"
export default class extends Controller {
  connect() {
    new Modal(this.element).show()
  }

  close() {
    Modal.getInstance(this.element).hide()
  }
}
