import {Controller} from "@hotwired/stimulus"
import ScrollBooster from 'scrollbooster';

let load = {
  delayDuration: 600,
  timer: null
}

// Connects to data-controller="selection-processes--tabs"
export default class extends Controller {
  static targets = ['controlItem', 'containerItem', 'tabsViewport', 'tabsList']

  initialize() {
    this.loaded()
  }

  loaded() {
    this.scrollBooster()
    this.loadShow()
  }

  resizing() {
    this.resize()
  }

  scrollBooster() {
    new ScrollBooster({
      viewport: this.tabsViewportTarget,
      content: this.tabsListTarget,
      direction: 'horizontal',
      scrollMode: 'transform'
    });
  }

  loadShow() {
    const jobClassName = document.getElementById('selection-processes').className.split(' ').pop(),
      skippedClassNames = ['new', 'continue', 'talents-index', 'talents-show', 'close']

    if (skippedClassNames.includes(jobClassName)) return

    const that = this

    clearTimeout(load.timer)

    load.timer = setTimeout(() => {
      that.toggleControl(jobClassName)
      that.toggleContainer(jobClassName)
    }, load.delayDuration)
  }

  toggleControl(className) {
    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })
  }

  toggleContainer(className) {
    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(className)) {
        const containerItemHeight = Math.ceil(element.querySelector('.div').getBoundingClientRect().height)

        element.classList.add('active')
        element.style.height = `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })
  }

  click(event) {
    event.preventDefault()

    const ActiveTabClassName = [].slice.apply(event.target.closest('li').classList).pop()

    this.controlItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        element.classList.add('active')
      } else {
        element.classList.contains('active') && element.classList.remove('active')
      }
    })

    this.containerItemTargets.forEach((element) => {
      if (element.classList.contains(ActiveTabClassName)) {
        const containerItemHeight = Math.ceil(element.querySelector('.div').getBoundingClientRect().height)

        element.classList.add('active')
        element.style.height = `${containerItemHeight}px`
      } else {
        if (element.classList.contains('active')) {
          element.classList.remove('active')
          element.style.height = null
        }
      }
    })
  }

  resize() {
    this.containerItemTargets.forEach((element) => {
      element.classList.contains('active') && (element.style.height = 'auto')
    })
  }
}
