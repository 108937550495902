import Handsontable from 'handsontable'

let importStatusRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties)

  switch (value) {
    case true:
      td.className = 'import-status valid'
      td.innerText = '✓'
      break

    case false:
      td.className = 'import-status invalid'
      td.innerText = '✗'
      break
  }
}

module.exports = { importStatusRenderer }
