import {Controller} from "@hotwired/stimulus"
import {DotLottie} from '@lottiefiles/dotlottie-web';
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);
// Connects to data-controller="home--index--benefits-animation"
export default class extends Controller {
  static targets = ['title', 'lottie', 'contentRowOne', 'contentRowTwo', 'contentRowThree',
    'containerRowOne', 'containerRowTwo']

  connect() {
    this.scrollingTrigger()
  }

  scrollingTrigger() {
    let animate = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.element,
      start: 'top 60%',
      end: 'bottom 30%',
      animation: animate,
      toggleActions: 'play none none none',
      onEnter: () => this.animateLottie(),
    })

    animate
      .fromTo(
        this.titleTarget,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.contentRowOneTargets,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )

    let animateRowTwo = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.containerRowOneTarget,
      start: 'top 30%',
      end: 'bottom 30%',
      animation: animateRowTwo,
      toggleActions: 'play none none none',
    })

    animateRowTwo
      .fromTo(
        this.contentRowTwoTargets,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )

    let animateRowThree = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.containerRowTwoTarget,
      start: 'top 30%',
      end: 'bottom 30%',
      animation: animateRowThree,
      toggleActions: 'play none none none',
    })

    animateRowThree
      .fromTo(
        this.contentRowThreeTargets,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
  }

  animateLottie() {
    this.lottieTargets.forEach((element) => {
      let canvas = element

      new DotLottie({
        canvas,
        src: element.getAttribute("src"),
        loop: true,
        autoplay: true
      });
    })
  }
}
