import {Controller} from "@hotwired/stimulus"

let load = {
  delayDuration: 300,
  timer: null
}

// Connects to data-controller="companies--information"
export default class extends Controller {
  static targets = ['mainList', 'newButtonTop', 'newButtonMain', 'newContainer', 'newForm']

  openNew(event) {
    event.preventDefault()

    const containerItemHeight = Math.ceil(this.newFormTarget.querySelector('.div').getBoundingClientRect().height)

    this.newContainerTarget.classList.add('active')
    this.newFormTarget.style.height = `${containerItemHeight}px`
  }

  closeNew(event) {
    event.preventDefault()

    this.newContainerTarget.classList.remove('active')
    this.newFormTarget.style.height = null
    this.closeNewToggleButton()
  }

  closeNewToggleButton() {
    this.hasNewButtonTopTarget && this.newButtonTopTarget.classList.toggle('hide', this.mainListTarget.childElementCount > 0)
    this.hasNewButtonMainTarget && this.newButtonMainTarget.classList.toggle('hide', this.mainListTarget.childElementCount === 0)
  }

  toggleNewButton() {
    const that = this

    addEventListener('turbo:before-stream-render', function(event) {
      const listElement = document.getElementById(event.target.target),
        listElementCount = listElement.childElementCount

      if (!listElement) return

      clearTimeout(load.timer)

      listElementCount > 0 || listElement.classList.add('adding')
      load.timer = setTimeout(function() {
        that.hasNewButtonTopTarget && that.newButtonTopTarget.classList.toggle('hide', listElement.childElementCount > 0)
        listElementCount > 0 || listElement.classList.remove('adding')
      }, load.delayDuration)
    })
  }
}
