import {Controller} from "@hotwired/stimulus"
import {driver} from 'driver.js';

// Connects to data-controller="talents--tour--new"
export default class extends Controller {
  static targets = ['fieldHighlight', 'submitHighlight', 'selectJob', 'input', 'inputEmail']

  connect() {
    if (window.innerWidth > 769) {
      let isRestartTalent = JSON.parse(localStorage.getItem('is_retake_talent_tour') === 'true')

      if (!companyTalentCountPositive && isRestartTalent) {
        this.thirdStepTalent()
      } else if (isRestartTalent) {
        this.thirdStepTalent()
      }
    }
  }

  thirdStepTalent() {
    const driverObjThirdStepTalent = driver({
      showProgress: true,
      progressText: '3/3',
      stagePadding: 15,
      stageRadius: 20,
      allowClose: false,
      overlayOpacity: 0.6,
      onPopoverRender: (popover) => {
        popover.closeButton.style.display = 'none'

        let skipBtn = document.createElement('button')
        skipBtn.innerText = talentsTourTranslations.btn
        skipBtn.classList.add('custom-skip-btn')
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener('click', () => {
          localStorage.setItem('is_retake_job_tour', false)
          localStorage.setItem('is_retake_talent_tour', false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjThirdStepTalent.destroy()
        })

        popover.description.removeChild(popover.description.childNodes[0])

        let nextBtn = document.createElement('button')
        nextBtn.innerText = talentsTourTranslations.steps.third.next
        nextBtn.classList.add('next-btn')
        popover.description.append(nextBtn)

        nextBtn.addEventListener('click', () => {
          this.thirdStepTalentSubmit()
        })

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        let hasActiveClass = popover.description.querySelector('.next-btn').classList.contains('active')

        this.inputTarget.addEventListener('input', (event) => {
          const value = event.target.value

          if (value.trim() && emailRegex.test(this.inputEmailTarget.value) && !hasActiveClass) {
            popover.description.querySelector('.next-btn').classList.add('active')
          } else {
            popover.description.querySelector('.next-btn').classList.remove('active')
          }
        })
        
        this.inputEmailTarget.addEventListener('input', (event) => {
          const value = event.target.value

          if (!emailRegex.test(this.inputEmailTarget.value)) {
            if (!this.inputEmailTarget.parentElement.querySelector('.error-message')) {
              const errorSpan = document.createElement('span')
              errorSpan.className = 'error-message'
              errorSpan.textContent = talentsTourTranslations.validate.email
              this.inputEmailTarget.parentElement.appendChild(errorSpan)

              driverObjThirdStepTalent.refresh()
            }
          } else {
            if (this.inputEmailTarget.parentElement.querySelector('.error-message')) {
              this.inputEmailTarget.parentElement.querySelector('.error-message').remove()

              driverObjThirdStepTalent.refresh()
            }
          }

          if (value.trim() && this.inputTarget.value.trim() && emailRegex.test(this.inputEmailTarget.value) && !hasActiveClass) {
            popover.description.querySelector('.next-btn').classList.add('active')
          } else {
            popover.description.querySelector('.next-btn').classList.remove('active')
          }
        })
      },
      steps: [{
        element: this.fieldHighlightTarget, popover: {
          title: talentsTourTranslations.steps.third.title,
          description: talentsTourTranslations.steps.third.description,
          side: 'top',
          align: 'end',
          showButtons: ['close'],
          popoverClass: 'third-step-talent',
        }
      }],
      onCloseClick: () => {
        localStorage.setItem('is_retake_job_tour', false)
        localStorage.setItem('is_retake_talent_tour', false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjThirdStepTalent.destroy();
      },
      onHighlighted: () => {
        const that = this
        
        this.selectJobTarget.querySelector('.ss-main').addEventListener('click', () => {
          this.element.querySelector('.select-options .ss-content').classList.toggle('active')
          driverObjThirdStepTalent.refresh()
        })

        this.selectJobTarget.querySelector('select').addEventListener('change', () => {
          this.element.querySelector('.select-options .ss-content').classList.remove('active')
          driverObjThirdStepTalent.refresh()
        })

        document.addEventListener('click', function(event) {
          if (!that.selectJobTarget.contains(event.target)) {
            that.element.querySelector('.select-options .ss-content').classList.remove('active')
            driverObjThirdStepTalent.refresh()
          }
        })
      }
    })

    driverObjThirdStepTalent.drive();
  }

  thirdStepTalentSubmit() {
    const driverObjThirdStepTalentSubmit = driver({
      showProgress: true,
      progressText: '3/3',
      stagePadding: 6,
      stageRadius: 50,
      allowClose: false,
      overlayOpacity: 0.6,
      onPopoverRender: (popover) => {
        popover.closeButton.style.display = 'none'

        let skipBtn = document.createElement('button')
        skipBtn.innerText = talentsTourTranslations.btn
        skipBtn.classList.add('custom-skip-btn')
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener('click', () => {
          localStorage.setItem('is_retake_job_tour', false)
          localStorage.setItem('is_retake_talent_tour', false)

          this.checkList()
          driverObjThirdStepTalentSubmit.destroy()
        })

        popover.description.removeChild(popover.description.childNodes[0])
      },
      steps: [{
        element: this.submitHighlightTarget, popover: {
          title: talentsTourTranslations.steps.third.submit,
          description: '',
          side: 'right',
          align: 'end',
          showButtons: ['close'],
          popoverClass: 'third-step-submit-talent',
        }
      }],
      onCloseClick: () => {
        localStorage.setItem('is_retake_job_tour', false)
        localStorage.setItem('is_retake_talent_tour', false)

        this.checkList()
        driverObjThirdStepTalentSubmit.destroy();
      }
    })

    driverObjThirdStepTalentSubmit.drive();
  }

  checkList() {
    const targetController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='layouts--check-list']"),
      'layouts--check-list'
    )

    targetController.showCheckList()
  }
}
