import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="companies--applications--form-acceptation"
export default class extends Controller {
  static targets = ['checkbox', 'submitContainer', 'submit']

  connect() {
    this.toggle()
  }

  check() {
    this.toggle()
  }

  toggle() {
    const isChecked = this.checkboxTarget.checked
    this.submitContainerTarget.classList.toggle('disabled', !isChecked)
    this.submitTarget.toggleAttribute('disabled', !isChecked)
  }
}
