import { Controller } from "@hotwired/stimulus"
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

// Connects to data-controller="home--index--experiencing-animation"
export default class extends Controller {
  static targets = ['title', 'chaoticContainer', 'highCostContainer', 'memberContainer', 'talentContainer']

  connect() {
    this.scrollingTrigger()
  }

  scrollingTrigger() {
    let animate = gsap.timeline()

    ScrollTrigger.create({
      trigger: this.element,
      start: 'top 80%',
      end: 'bottom 30%',
      animation: animate,
      toggleActions: 'play none none none',
    })

    animate
      .fromTo(
        this.titleTarget,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.chaoticContainerTarget,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.highCostContainerTarget,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.memberContainerTarget,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.talentContainerTarget,
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
  }
}
