import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

// Connects to data-controller="transfer--export"
export default class extends Controller {
  static targets = ['form', 'select', 'btn']

  connect() {
    this.selectOptions()
  }

  selectOptions() {
    if (!this.hasSelectTarget) return

    const that = this,
      inputCheckedOptions = this.selectTarget.querySelector('option:checked')

    new SlimSelect({
      select: that.selectTarget,
      settings: {
        showSearch: false,
        openPosition: 'down'
      },
      events: {
        afterChange: (info) => {
          const value = info.shift().value

          that.formTarget.setAttribute('action', value)
          that.btnTarget.classList.toggle('disabled', !value)
        }
      }
    })

    if (!inputCheckedOptions) return

    this.btnTarget.classList.toggle('disabled', !inputCheckedOptions.value)
  }
}
