import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

// Connects to data-controller="jobs--inputs--select"
export default class extends Controller {
  static targets = ['select', 'selectStatus', 'selectMultiple']

  connect() {
    this.selectOptions()
    this.selectStatus()
    this.selectMultipleOptions()
  }

  loadHiddenSelect(inputHidden, values) {
    inputHidden.setAttribute('value', values.join('-'))
  }

  selectOptions() {
    if (!this.hasSelectTarget) return

    this.selectTargets.forEach((element) => {
      const that = this,
        inputCheckedOptions = element.querySelector('option:checked')

      new SlimSelect({
        select: element,
        settings: {
          searchPlaceholder: jobSearchFormPlaceholderTranslations.search,
          searchText: jobSearchFormPlaceholderTranslations.no_data_found,
          searchHighlight: true,
          openPosition: 'down'
        }
      })
    })
  }

  selectStatus() {
    if (!this.hasSelectStatusTarget) return

    this.selectStatusTargets.forEach((element) => {

      new SlimSelect({
        select: element,
        settings: {
          openPosition: 'down',
          showSearch: false
        }
      })
    })
  }


  selectMultipleOptions() {
    if (!this.hasSelectMultipleTarget) return

    this.selectMultipleTargets.forEach((element) => {
      const that = this,
        inputHidden = element.parentNode.querySelector('input[type=hidden]'),
        inputCheckedOptions = element.querySelectorAll('option:checked'),
        inputName = element.dataset.name || element.name

      new SlimSelect({
        select: element,
        settings: {
          placeholderText: jobSearchFormPlaceholderTranslations[`${inputName}`],
          searchPlaceholder: jobSearchFormPlaceholderTranslations.active_jobs.search,
          searchText: jobSearchFormPlaceholderTranslations.no_data_found,
          searchHighlight: true,
          openPosition: 'down'
        },
        events: {
          afterChange: (info) => {
            that.loadHiddenSelect(inputHidden, that.convertToArray(info))
          }
        }
      })

      this.loadHiddenSelect(inputHidden, this.convertToArray(inputCheckedOptions))
      element.removeAttribute('name')
    })
  }

  convertToArray(obj) {
    return Array.from(obj).map(obj => obj.value).sort((a, b) => {
      return a - b
    })
  }
}
