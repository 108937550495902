import {Controller} from "@hotwired/stimulus"
import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);

// Connects to data-controller="home--banner-animation"
export default class extends Controller {
  static targets = ['bannerTitle', 'bannerDescription']

  connect() {
    this.scrollingTrigger()
  }

  scrollingTrigger() {
    let banner = gsap.timeline()

    banner
      .fromTo(
        this.bannerTitleTarget.children[0],
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.bannerTitleTarget.children[0].children[0],
        { y: 100, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
      .fromTo(
        this.bannerDescriptionTarget,
        { y: 50, opacity: 0, duration: 0.5 },
        { y: 0, opacity: 1, duration: 0.5 }
      )
  }
}
