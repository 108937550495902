import {Controller} from "@hotwired/stimulus"

let expandingFormDuration = 600

// Connects to data-controller="selection-processes--inputs--form"
export default class extends Controller {
  static targets = ['form', 'textarea', 'container']

  initialize() {
    this.loaded()
  }

  connect() {
    this.events()
  }

  loaded() {
    this.expandingForm()
    this.loadForm()
    this.loadFormBottom()
  }

  events() {
    this.clickOutside()
    this.addClassNameListener()
  }

  resizing() {
    this.loadForm()
    this.loadFormBottom()
  }

  scrolling() {
    this.toggleFormBottom()
  }

  click(event) {
    event.preventDefault()

    this.formTarget.parentNode.submit()
  }

  clickOutside() {
    const that = this

    document.addEventListener('click', function(event) {
      const containerFormAround = that.element.contains(event.target),
        sidebarBtn = event.target.closest('.btn'),
        containerFormVisible = window.getComputedStyle(that.element).visibility !== 'hidden',
        sidebarBtnVisible = event.target.closest('.btn') ?
          window.getComputedStyle(event.target.closest('.btn')).visibility !== 'hidden' : true,
        leftClicked = (event.button === 1)

      !containerFormAround && containerFormVisible && !sidebarBtn && sidebarBtnVisible && leftClicked && that.closeForm()
    })
  }

  addClassNameListener() {
    let elem = document.getElementById('page');
    let lastClassName = elem.className;
    const that = this

    window.setInterval(function() {
      let className = elem.className;
      if (className !== lastClassName) {
        let timeOut = elem.classList.contains('expand') ? 0 : 300

        setTimeout(function() {
          that.element.classList.toggle('sidebar-expanded', elem.classList.contains('expand'))
        }, timeOut);
        lastClassName = className;
      }
    }, 5);
  }

  toggleExpandingForm(state) {
    this.element.classList.toggle('expanding', state)
  }

  expandingForm() {
    if (!this.hasContainerTarget) return

    const that = this

    that.toggleExpandingForm(true)

    setTimeout(function() {
      that.toggleExpandingForm(false)
    }, expandingFormDuration)
  }

  loadForm() {
    if (!this.hasContainerTarget) return

    const that = this,
      isSidebarExpand = localStorage.getItem('isSidebarExpand') === 'true',
      isFormExpand = localStorage.getItem('isFormExpand') === 'true'

    this.element.classList.toggle('sidebar-expanded', isSidebarExpand)
    this.element.classList.toggle('expand', isFormExpand)
    this.element.style.height = `${this.containerTarget.clientHeight}px`
    this.element.style.overflow = 'hidden'

    setTimeout(function() {
      that.element.style.overflow = null
    }, expandingFormDuration)
  }

  loadFormBottom() {
    const that = this

    setTimeout(function() {
      const footerTop = document.querySelector('footer').offsetTop,
        bottom = window.innerHeight - footerTop

      that.element.style.bottom = bottom > 0 ? `${bottom}px` : null
    }, expandingFormDuration)
  }

  openForm() {
    localStorage.setItem('isFormExpand', true)
    this.loadForm()
  }

  closeForm(event) {
    event && event.preventDefault()

    localStorage.setItem('isFormExpand', false)
    this.loadForm()
  }

  toggleForm() {
    const isExpand = localStorage.getItem('isFormExpand') === 'true'
    localStorage.setItem('isFormExpand', !isExpand)
    this.loadForm()
  }

  toggleFormBottom() {
    const windowTop = window.scrollY,
      footerTop = document.querySelector('footer').offsetTop,
      isStopped = (windowTop + window.innerHeight) > footerTop

    this.element.style.bottom = isStopped ? `${(windowTop + window.innerHeight) - footerTop}px` : null
  }
}
