import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select';

// Connects to data-controller="talents--inputs--select-date"
export default class extends Controller {
  connect() {
    this.selectOptions()
  }

  selectOptions() {
    const children = Array.from(this.element.querySelectorAll('select'))

    if (!children.length) return

    children.forEach(element => {
      const that = this,
        inputCheckedOptions = element.querySelector('option:checked')

      new SlimSelect({
        select: element,
        settings: {
          searchHighlight: true,
          openPosition: 'up'
        }
      })
    })
  }
}
