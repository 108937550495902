function closeOtherAttachment(otherAttachmentContainer) {
  otherAttachmentContainer.forEach((element) => {
    if (element.classList.contains('active')) { element.classList.remove('active') }
  })
}

// function clickOutsideAttachment(attachmentContainer, td) {
//   document.addEventListener('click', function(event) {
//     const containerAttachmentAround = attachmentContainer.contains(event.target),
//       containerAttachmentVisible = window.getComputedStyle(attachmentContainer).visibility !== 'hidden',
//       containerAttachmentArea = !containerAttachmentAround && containerAttachmentVisible,
//       tdAround = td.contains(event.target),
//       tdVisible = window.getComputedStyle(td).visibility !== 'hidden',
//       tdArea = !tdAround && tdVisible,
//       leftClicked = (event.button === 1)
//
//     if (containerAttachmentArea && tdArea && leftClicked) { attachmentContainer.classList.remove('active') }
//   })
// }

function assignAttachmentRow(attachmentContainer, row) {
  attachmentContainer.querySelector('input[name="talent[row]"]').value = row
}

function deSelect(attachmentContainer) {
  // attachmentContainer.querySelector('.ss-deselect').click()
}

const handleCellMouseDown = function(table, attachmentContainer, otherAttachmentContainer, propName) {
  table.addHook('afterOnCellMouseDown', (event, coords, td) => {
    if (event.button !== 0) return

    const prop = table.colToProp(coords.col)

    if (prop === propName) {
      closeOtherAttachment(otherAttachmentContainer)
      attachmentContainer.classList.add('active')

      assignAttachmentRow(attachmentContainer, coords.row)
      deSelect(attachmentContainer)

      // clickOutsideAttachment(attachmentContainer, td)
    }
  })
}

const cv = function(table, attachmentContainer, otherAttachmentContainer) {
  handleCellMouseDown(table, attachmentContainer, otherAttachmentContainer, 'cv')
}

const additionalFile1 = function(table, attachmentContainer, otherAttachmentContainer) {
  handleCellMouseDown(table, attachmentContainer, otherAttachmentContainer, 'additional_file1')
}

const additionalFile2 = function(table, attachmentContainer, otherAttachmentContainer) {
  handleCellMouseDown(table, attachmentContainer, otherAttachmentContainer, 'additional_file2')
}

module.exports = { cv, additionalFile1, additionalFile2 }