import {Controller} from '@hotwired/stimulus'
import Flicking from "@egjs/flicking";
import {AutoPlay, Pagination} from "@egjs/flicking-plugins";

// Connects to data-controller="home--partner-sliders"
export default class extends Controller {
  static targets = ['prevBtn', 'nextBtn', 'panel', 'pagination']

  connect() {
    const flicking = new Flicking("#my-flicking", {
      circular: true,
      align: 'prev'
    });

    flicking.addPlugins(new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: false }));

    if (!this.hasPagination && !this.hasPrevBtnTarget && !this.hasNextBtnTarget) return

    flicking.addPlugins(new Pagination(
      {
        parentEl: this.element,
        selector: ".flicking-pagination",
        type: "bullet"
      }));

    this.prevBtnTarget.addEventListener('click', function(event) {
      flicking.prev()
    })

    this.nextBtnTarget.addEventListener('click', function(event) {
      flicking.next()
    })
  }
}
