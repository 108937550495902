import {Controller} from "@hotwired/stimulus"
import {driver} from 'driver.js';
import {DotLottie} from '@lottiefiles/dotlottie-web';
import Flicking from "@egjs/flicking";
import {AutoPlay, Pagination} from "@egjs/flicking-plugins";

function createPanel(tour, heading, imgSrc, imgAlt) {
  const panel = document.createElement("div")
  panel.className = "flicking-panel"

  const content = document.createElement("div")
  content.className = "content"

  const headingDiv = document.createElement("div")
  headingDiv.className = "heading";
  headingDiv.innerHTML = `<span>${tour}</span> : ${heading}`

  const imgIntro = document.createElement("div")
  imgIntro.className = "img-intro"
  const img = document.createElement("img")
  img.src = imgSrc
  img.alt = imgAlt

  imgIntro.appendChild(img)
  content.appendChild(headingDiv)
  content.appendChild(imgIntro)
  panel.appendChild(content)

  return panel
}

function createContent(number, textTitle, description, gif) {
  const contentDiv = document.createElement("div")
  contentDiv.className = "content";

  const imgGifDiv = document.createElement("div")
  imgGifDiv.className = "img-gif"
  const imgElement = document.createElement("img")
  imgElement.src = gif
  imgElement.alt = number
  imgGifDiv.appendChild(imgElement)

  const titleDiv = document.createElement("div")
  titleDiv.className = "title"
  titleDiv.innerHTML = `<span>${number}</span> : ${textTitle}`

  const detailsDiv = document.createElement("div")
  detailsDiv.className = "details";
  detailsDiv.textContent = description

  contentDiv.appendChild(imgGifDiv)
  contentDiv.appendChild(titleDiv)
  contentDiv.appendChild(detailsDiv)

  return contentDiv
}

// Connects to data-controller="dashboard--tour"
export default class extends Controller {
  static targets = ['modal']

  connect() {
    if (window.innerWidth > 769) {
      this.touringCheck()
    }
  }

  touringCheck() {
    let isRestartJob = JSON.parse(localStorage.getItem("is_retake_job_tour") === 'true'),
      isRestartTalent = JSON.parse(localStorage.getItem("is_retake_talent_tour") === 'true')

    if ((parseInt(companyMemberSingInCount) === 0) && !(localStorage.getItem('isFirstLogin'))) {
      localStorage.setItem('is_skip_checklist_for_now', true)
      this.welcomeMessage()
    } else {
      if (!companyJobCountPositive && !companyTalentCountPositive && isRestartJob && isRestartTalent) {
        localStorage.setItem('is_skip_checklist_for_now', true)
        this.tourIntroduction()
      } else if (isRestartJob) {
        this.jobCreateTour()
      } else if (isRestartTalent) {
        this.talentCreateTour()
      }
    }
  }

  welcomeMessage() {
    let first_name = companyMemberName.split(/(\s+)/)

    const driverObj = driver({
      allowClose: false, onPopoverRender: (popover) => {
        popover.closeButton.style.display = ''
      }, doneBtnText: welcomeMessageTranslations.btn, overlayOpacity: 0.6, steps: [{
        popover: {
          popoverClass: 'welcome-message',
          showButtons: ["close", 'next'],
          align: 'center',
          title: this.buildPopoverTitle(welcomeMessageTranslations.title, first_name[0], lottie),
          description: this.buildPopoverDescription(welcomeMessageTranslations.description)
        }
      }], onNextClick: () => {
        localStorage.setItem('isFirstLogin', true)

        if (companyJobCountPositive) {
          localStorage.setItem("is_retake_job_tour", false)
          localStorage.setItem("is_retake_talent_tour", false)

          this.checkList()
          driverObj.destroy()
        } else {
          localStorage.setItem("is_retake_job_tour", true)
          localStorage.setItem("is_retake_talent_tour", true)

          driverObj.destroy()

          setTimeout(() => {
            this.tourIntroduction()
          }, 500)
        }
      }, onCloseClick: () => {
        localStorage.setItem("is_retake_job_tour", false)
        localStorage.setItem("is_retake_talent_tour", false)

        this.checkList()
        driverObj.destroy();
      },
    })

    driverObj.drive()
    this.animateLottie()
  }

  buildPopoverTitle(title, name, lottie) {
    return `
      <div>
        <canvas id="test-lottie" src="${lottie}" style="width:150px; height:150px; margin:auto"></canvas>
        <h3>${title}, <span>${name}</span> !</h3>
      </div>
    `
  }

  buildPopoverDescription(description) {
    return `<span>${description}</span>`
  }

  animateLottie() {
    let canvas = document.querySelector('#test-lottie')
    new DotLottie({
      canvas, src: canvas.getAttribute("src"), loop: false, autoplay: true
    });
  }

  tourIntroduction() {
    let img_tour_job = imgIntroJob,
      img_tour_talent = imgIntroTalent,
      job_tour = introductionTranslations.list.job.tour,
      job_heading = introductionTranslations.list.job.heading,
      talent_tour = introductionTranslations.list.talent.tour,
      talent_heading = introductionTranslations.list.talent.heading

    const driverObjIntro = driver({
      allowClose: false, onPopoverRender: (popover) => {

        popover.closeButton.style.display = ''

        let skipBtn = document.createElement("button")
        skipBtn.innerText = introductionTranslations.btn.skip
        skipBtn.classList.add("custom-skip-btn")
        popover.footerButtons.prepend(skipBtn)


        skipBtn.addEventListener("click", () => {
          localStorage.setItem("is_retake_job_tour", false)
          localStorage.setItem("is_retake_talent_tour", false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjIntro.destroy()
        })

        const flickingViewport = document.createElement("div")
        flickingViewport.id = "my-flicking";
        flickingViewport.className = "flicking-viewport";

        const flickingCamera = document.createElement("div")
        flickingCamera.className = "flicking-camera"

        const pagination = document.createElement("div")
        pagination.className = "flicking-pagination"


        const jobPanel = createPanel(job_tour, job_heading, img_tour_job, job_tour)
        const talentPanel = createPanel(talent_tour, talent_heading, img_tour_talent, talent_tour)
        flickingCamera.appendChild(jobPanel)
        flickingCamera.appendChild(talentPanel)

        flickingViewport.appendChild(flickingCamera)
        flickingViewport.appendChild(pagination)

        popover.description.appendChild(flickingViewport)
        popover.description.removeChild(popover.description.childNodes[0])
        popover.description.style.display = 'block'

        this.slideIntroduction()

        driverObjIntro.refresh()

      }, overlayOpacity: 0.6, doneBtnText: introductionTranslations.btn.start, nextBtnText: 'text', steps: [{
        popover: {
          popoverClass: 'introduction',
          showButtons: ["close", 'next'],
          align: 'center',
          title: this.buildPopoverIntroTitle(introductionTranslations.title),
        }
      }], onNextClick: () => {
        driverObjIntro.destroy();

        setTimeout(() => {
          this.jobCreateTour()
        }, 500)
      }, onCloseClick: () => {
        localStorage.setItem("is_retake_job_tour", false)
        localStorage.setItem("is_retake_talent_tour", false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjIntro.destroy();
      }
    })

    driverObjIntro.drive()
  }

  buildPopoverIntroTitle(title) {
    return `
      <div>
        <h3>${title}</h3>
      </div>
    `
  }

  slideIntroduction() {
    const flicking = new Flicking("#my-flicking", {
      circular: true, align: 'prev'
    })

    flicking.addPlugins(new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: false }))
    flicking.addPlugins(new Pagination({
      type: "bullet"
    }))
  }

  jobCreateTour() {
    let gif = imgJob,
      job_tour_no = jobsTourTranslations.heading.title,
      job_tour_text = jobsTourTranslations.heading.text,
      job_description = jobsTourTranslations.description

    const driverObjJob = driver({
      allowClose: false, onPopoverRender: (popover) => {
        popover.closeButton.style.display = ''

        let skipBtn = document.createElement("button")
        skipBtn.innerText = jobsTourTranslations.btn.skip
        skipBtn.classList.add("custom-skip-btn")
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener("click", () => {
          localStorage.setItem("is_retake_job_tour", false)
          localStorage.setItem("is_retake_talent_tour", false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjJob.destroy()
        })

        const content = createContent(job_tour_no, job_tour_text, job_description, gif)

        popover.description.appendChild(content)
        popover.description.removeChild(popover.description.childNodes[0])
        popover.description.style.display = 'block'

        driverObjJob.refresh()

      }, overlayOpacity: 0.6, doneBtnText: introductionTranslations.btn.start, nextBtnText: 'text', steps: [{
        popover: {
          popoverClass: 'job-tour',
          showButtons: ["close", 'next'],
          align: 'center',
          title: '',
        }
      }], onNextClick: () => {
        this.firstStepJob()
      }, onCloseClick: () => {
        localStorage.setItem("is_retake_job_tour", false)
        localStorage.setItem("is_retake_talent_tour", false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjJob.destroy();
      },
    })

    driverObjJob.drive()
  }

  firstStepJob() {
    const driverObjFirstStepJob = driver({
      showProgress: true,
      progressText: '1/3',
      stagePadding: 6,
      stageRadius: 50,
      allowClose: false,
      overlayOpacity: 0.6,
      onPopoverRender: (popover) => {
        popover.closeButton.style.display = 'none'

        let skipBtn = document.createElement("button")
        skipBtn.innerText = jobsTourTranslations.steps.btn
        skipBtn.classList.add("custom-skip-btn")
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener("click", () => {
          localStorage.setItem('is_retake_job_tour', false)
          localStorage.setItem('is_retake_talent_tour', false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjFirstStepJob.destroy()
        })
      },
      steps: [{
        element: document.querySelector('header .bottom .header-menu .list .jobs a'), popover: {
          title: jobsTourTranslations.steps.first.title,
          side: "right",
          align: 'end',
          showButtons: ["close"],
          popoverClass: 'first-step-job',
        }
      }],
      onCloseClick: () => {
        localStorage.setItem('is_retake_job_tour', false)
        localStorage.setItem('is_retake_talent_tour', false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjFirstStepJob.destroy();
      },
    });

    driverObjFirstStepJob.drive();
  }

  talentCreateTour() {
    let gif = imgTalent,
      talent_tour_no = talentsTourTranslations.heading.title,
      talent_tour_text = talentsTourTranslations.heading.text,
      talent_description = talentsTourTranslations.description

    const driverObjJob = driver({
      allowClose: false, onPopoverRender: (popover) => {
        popover.closeButton.style.display = ''

        let skipBtn = document.createElement("button")
        skipBtn.innerText = talentsTourTranslations.btn.skip
        skipBtn.classList.add("custom-skip-btn")
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener("click", () => {
          localStorage.setItem("is_retake_job_tour", false)
          localStorage.setItem("is_retake_talent_tour", false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjJob.destroy()
        })

        const content = createContent(talent_tour_no, talent_tour_text, talent_description, gif)

        popover.description.appendChild(content)
        popover.description.removeChild(popover.description.childNodes[0])
        popover.description.style.display = 'block'

      }, overlayOpacity: 0.6, doneBtnText: talentsTourTranslations.btn.start, nextBtnText: 'text', steps: [{
        popover: {
          popoverClass: 'talent-tour',
          showButtons: ["close", 'next'],
          align: 'center',
          title: '',
        }
      }], onNextClick: () => {
        this.firstStepTalent()
      }, onCloseClick: () => {
        localStorage.setItem("is_retake_job_tour", false)
        localStorage.setItem("is_retake_talent_tour", false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjJob.destroy();
      },
    })

    driverObjJob.drive()
  }

  firstStepTalent() {
    const driverObjFirstStepJob = driver({
      showProgress: true,
      progressText: '1/3',
      stagePadding: 6,
      stageRadius: 50,
      allowClose: false,
      overlayOpacity: 0.6,
      onPopoverRender: (popover) => {
        popover.closeButton.style.display = 'none'

        let skipBtn = document.createElement("button")
        skipBtn.innerText = talentsTourTranslations.steps.btn
        skipBtn.classList.add("custom-skip-btn")
        popover.footerButtons.prepend(skipBtn)

        skipBtn.addEventListener("click", () => {
          localStorage.setItem("is_retake_job_tour", false)
          localStorage.setItem("is_retake_talent_tour", false)
          localStorage.setItem('is_skip_checklist_for_now', false)

          this.checkList()
          driverObjFirstStepJob.destroy()
        })
      },
      steps: [{
        element: document.querySelector('header .bottom .header-menu .list .talents a'), popover: {
          title: talentsTourTranslations.steps.first.title,
          side: "right",
          align: 'end',
          showButtons: ["close"],
          popoverClass: 'first-step-talent',
        }
      }],
      onCloseClick: () => {
        localStorage.setItem("is_retake_job_tour", false)
        localStorage.setItem("is_retake_talent_tour", false)
        localStorage.setItem('is_skip_checklist_for_now', false)

        this.checkList()
        driverObjFirstStepJob.destroy();
      },
    });

    driverObjFirstStepJob.drive();
  }

  checkList() {
    const targetController = this.application.getControllerForElementAndIdentifier(
      document.querySelector("[data-controller='layouts--check-list']"),
      'layouts--check-list'
    )

    targetController.showCheckList()
  }
}
