import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="layouts--check-list"
export default class extends Controller {
  static targets = ['checklistContainer']

  connect() {
    let today = new Date().toDateString(),
      lastVisit = localStorage.getItem("lastVisitDate"),
      isSkipChecklist = JSON.parse(localStorage.getItem('is_skip_checklist_for_now') === 'true'),
      isRestartJob = JSON.parse(localStorage.getItem('is_retake_job_tour') === 'true'),
      isRestartTalent = JSON.parse(localStorage.getItem('is_retake_talent_tour') === 'true')

    if (isSkipChecklist && localStorage.hasOwnProperty('lastVisitDate') && (lastVisit !== today) && !companyJobCountPositive && !companyTalentCountPositive) {
      isSkipChecklist = false
    }

    if (!isSkipChecklist && (!isRestartJob && !isRestartTalent)) {
      this.showCheckList()
    }
  }

  showCheckList() {
    if (window.innerWidth > 769) {
      const today = new Date().toDateString()

      this.element.classList.add('active')
      localStorage.setItem("lastVisitDate", today);

      localStorage.setItem("is_retake_job_tour", false)
      localStorage.setItem("is_retake_talent_tour", false)
    }
  }

  playTourJob() {
    localStorage.setItem("is_retake_job_tour", true)
    localStorage.setItem('is_skip_checklist_for_now', true)
  }

  playTourTalent() {
    localStorage.setItem("is_retake_talent_tour", true)
    localStorage.setItem('is_skip_checklist_for_now', true)
  }

  close() {
    localStorage.setItem("is_retake_job_tour", false)
    localStorage.setItem("is_retake_talent_tour", false)

    localStorage.setItem('is_skip_checklist_for_now', true)
    this.element.classList.remove('active')
  }
}
