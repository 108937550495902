import {Controller} from "@hotwired/stimulus"
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import LazyLoad from 'vanilla-lazyload'
import SmoothScroll from 'smooth-scroll'

let breakpoint = 768,
  expandingSidebarDuration = 600,
  resize = {
    delayDuration: 600,
    timer: null
  },
  smoothScroll = {
    headerSelector: 'header nav',
    targetSelector: `#content ${location.hash}`,
    speed: 600,
    duration: 900
  },
  windowWidth = window.innerWidth

// Connects to data-controller="layouts"
export default class extends Controller {
  static targets = ['sidebar', 'sidebarBtn']

  initialize() {
    this.loaded()
  }

  connect() {
    this.events()
  }

  loaded() {
    this.lazyLoaded()
    this.smoothScroll()
    this.smoothScrollAuto()

    this.expandingSidebar()
    this.loadSidebar()
  }

  events() {
    this.clickOutside()
  }

  resizing() {
    this.resizingHeader()
  }

  lazyLoaded() {
    new LazyLoad({
      elements_selector: '.lazy',
      callback_loaded: function(element) {
        element.classList.remove('lazy')
        element.removeAttribute('data-srcset')

        if (!element.closest('.lazy-loading')) return

        element.closest('.lazy-loading').removeAttribute('data-srcset')
        element.closest('.lazy-loading').classList.remove('lazy-loading')
      }
    })
  }

  smoothScroll() {
    new SmoothScroll('a[href*="#"]', {
      header: smoothScroll.headerSelector,
      speed: smoothScroll.speed,
      speedAsDuration: true,
      updateURL: false
    })
  }

  smoothScrollAuto() {
    if (window.location.href.indexOf('#_=_') > 0) return

    const target = location.hash && document.querySelector(smoothScroll.targetSelector)

    target && setTimeout(function() {
      new SmoothScroll().animateScroll(target, null, {
        header: smoothScroll.headerSelector,
        speed: smoothScroll.speed,
        speedAsDuration: true,
        updateURL: false
      })
    }, smoothScroll.duration)
  }

  loadSidebar() {
    if (!this.hasSidebarTarget) return

    const isExpand = localStorage.getItem('isSidebarExpand') === 'true'

    this.element.classList.toggle('expand', isExpand)
    this.sidebarTarget.classList.toggle('expand', isExpand)

    if (window.innerWidth > breakpoint) return

    document.body.classList.toggle('disabled', isExpand)
    isExpand ? disableBodyScroll(this.sidebarTarget) : enableBodyScroll(this.sidebarTarget)
  }

  toggleSidebar() {
    const isExpand = localStorage.getItem('isSidebarExpand') === 'true'
    localStorage.setItem('isSidebarExpand', !isExpand)

    this.loadSidebar()
  }

  toggleExpandingSidebar(state) {
    this.element.classList.toggle('expanding', state)
    this.sidebarTarget.classList.toggle('expanding', state)
  }

  expandingSidebar() {
    if (!this.hasSidebarTarget) return

    const that = this

    that.toggleExpandingSidebar(true)

    setTimeout(function() {
      that.toggleExpandingSidebar(false)
    }, expandingSidebarDuration)
  }

  toggleResizingHeader(state) {
    this.element.classList.toggle('resizing', state)
    this.sidebarTarget.classList.toggle('resizing', state)
  }

  resizingHeader() {
    if (!this.hasSidebarTarget) return

    if (windowWidth === window.innerWidth) return

    window.innerWidth <= breakpoint && this.collapseSidebar()

    const that = this

    that.toggleResizingHeader(true)
    clearTimeout(resize.timer)

    resize.timer = setTimeout(() => {
      that.toggleResizingHeader(false)
    }, resize.delayDuration)

    windowWidth = window.innerWidth
  }

  collapseSidebar() {
    if (!(this.element.classList.contains('expand') && this.sidebarTarget.classList.contains('expand'))) return

    localStorage.setItem('isSidebarExpand', false)
    this.loadSidebar()
  }

  clickOutside() {
    if (!this.hasSidebarTarget) return

    const that = this

    document.addEventListener('click', function(event) {
      if (window.innerWidth > breakpoint) return

      const sidebarAround = that.sidebarTarget.contains(event.target),
        sidebarBtnAround = that.sidebarBtnTarget.contains(event.target),
        sidebarVisible = window.getComputedStyle(that.sidebarTarget).visibility !== 'hidden',
        sidebarBtnVisible = window.getComputedStyle(that.sidebarBtnTarget).visibility !== 'hidden',
        leftClicked = (event.button === 1)

      !sidebarAround && sidebarVisible && !sidebarBtnAround && sidebarBtnVisible && leftClicked && that.collapseSidebar()
    })
  }
}
