import Handsontable from 'handsontable'

currentCompanyMemberId = checkWindowObject('currentCompanyMemberId')
currentCompanyMemberIsAdmin = checkWindowObject('currentCompanyMemberIsAdmin')

function checkWindowObject(obj) { return typeof window[obj] !== 'undefined' && window[obj] }

let companyMemberRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties)

  if(currentCompanyMemberIsAdmin) {
    cellProperties.type = 'dropdown'
  } else {
    td.innerText = currentCompanyMemberId
  }

  cellProperties.readOnly = !currentCompanyMemberIsAdmin
}

module.exports = { companyMemberRenderer }
