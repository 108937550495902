import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="my-tasks--tasks-list"
export default class extends Controller {
  static targets = ['notCompleted', 'completed', 'noRecordFound',
    'notCompletedMobile', 'completedMobile', 'noRecordFoundMobile']

  checkNoRecordFound() {
    const that = this

    addEventListener('turbo:before-stream-render', function(event) {
      const fallbackToDefaultActions = event.detail.render

      event.detail.render = function (streamElement) {
        const notCompletedListElement = that.notCompletedTarget,
          notCompletedListMobileElement = that.notCompletedMobileTarget,
          completedListElement = that.completedTarget,
          completedListMobileElement = that.completedMobileTarget

        that.clearPreviousNoRecordFound()
        that.clearPaginate()

        notCompletedListElement.childElementCount > 0 ||
        (notCompletedListElement.innerHTML = myTasksNoRecordFoundHTML)

        notCompletedListMobileElement.childElementCount > 0 ||
        (notCompletedListMobileElement.innerHTML = myTasksNoRecordFoundMobileHTML)

        completedListElement.childElementCount > 0 ||
        (completedListElement.innerHTML = myTasksNoRecordFoundHTML)

        completedListMobileElement.childElementCount > 0 ||
        (completedListMobileElement.innerHTML = myTasksNoRecordFoundMobileHTML)

        fallbackToDefaultActions(streamElement)
      }
    })
  }

  clearPreviousNoRecordFound() {
    this.hasNoRecordFoundTarget && this.noRecordFoundTarget.remove()
    this.hasNoRecordFoundMobileTarget && this.noRecordFoundMobileTarget.remove()
  }

  clearPaginate() {
    const paginate = this.element.querySelector('.pagination')

    paginate && paginate.classList.add('disabled')
  }
}


