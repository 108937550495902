import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="talents--menu"
export default class extends Controller {
  static targets = ['menu', 'menuList', 'menuInnerList']

  connect() {
    this.events()
  }

  resizing() {
    this.close()
  }

  toggle() {
    this.menuTarget.classList.toggle('active')
    this.menuListTarget.style.height =
      this.menuTarget.classList.contains('active') ? `${this.menuInnerListTarget.clientHeight}px` : null
  }

  close() {
    this.menuTarget.classList.remove('active')
    this.menuListTarget.style.height = null
  }

  clickOutsideType() {
    const that = this

    document.addEventListener('click', function(event) {
      const containerTypeAround = that.menuTarget.contains(event.target),
        containerTypeVisible = window.getComputedStyle(that.menuTarget).visibility !== 'hidden',
        leftClicked = (event.button === 1)

      !containerTypeAround && containerTypeVisible && leftClicked && that.close()
    })
  }

  events() {
    this.clickOutsideType()
  }
}
