import Handsontable from 'handsontable'

currentCompanyMemberDisplaySalary = checkWindowObject('currentCompanyMemberDisplaySalary')

function checkWindowObject(obj) { return typeof window[obj] !== 'undefined' && window[obj] }

let salaryRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties)

  if (currentCompanyMemberDisplaySalary) {
    cellProperties.type = 'numeric'
    cellProperties.numericFormat = { pattern: '0,0' }
    cellProperties.validator = 'salaryValidator'
  } else {
    td.innerText = '-'
  }

  cellProperties.readOnly = !currentCompanyMemberDisplaySalary
}

module.exports = { salaryRenderer }
